import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "AboutExploreItemCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            exploreItemOneTitle: null,
            exploreItemOneQuantity: null,
            exploreItemTwoTitle: null,
            exploreItemTwoQuantity: null,
            exploreItemThreeTitle: null,
            exploreItemThreeQuantity: null,
            exploreItemFourTitle: null,
            exploreItemFourQuantity: null,
            itemOneImage: null,
            itemTwoImage: null,
            itemThreeImage: null,
            itemFourImage: null,
            imageOne: null,
            imageTwo: null,
            imageThree: null,
            imageFour: null
        }
    },
    created() {
        document.title = "Gun Buy Sell - About Explore Item CMS";
        this.getExploreItemCMS();
    },
    methods: {
        getExploreItemCMS: function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.about.getExploreItemCmsURL,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data[0];
                    this.exploreItemOneTitle = this.dataList.itemOneTitle;
                    this.exploreItemOneQuantity = this.dataList.itemOneQuantity;
                    this.exploreItemTwoTitle = this.dataList.itemTwoTitle;
                    this.exploreItemTwoQuantity = this.dataList.itemTwoQuantity;
                    this.exploreItemThreeTitle = this.dataList.itemThreeTitle;
                    this.exploreItemThreeQuantity = this.dataList.itemThreeQuantity;
                    this.exploreItemFourTitle = this.dataList.itemFourTitle;
                    this.exploreItemFourQuantity = this.dataList.itemFourQuantity;
                    this.imageOne = this.dataList.itemOneImage;
                    this.imageTwo = this.dataList.itemTwoImage;
                    this.imageThree = this.dataList.itemThreeImage;
                    this.imageFour = this.dataList.itemFourImage;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onItemOneImage(event) {
            this.itemOneImage = event.target.files[0];
        },
        onItemTwoImage(event) {
            this.itemTwoImage = event.target.files[0];
        },
        onItemThreeImage(event) {
            this.itemThreeImage = event.target.files[0];
        },
        onItemFourImage(event) {
            this.itemFourImage = event.target.files[0];
        },
        update: function() {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("itemOneTitle", this.exploreItemOneTitle);
            formData.append("itemOneQuantity", this.exploreItemOneQuantity);
            formData.append("itemTwoTitle", this.exploreItemTwoTitle);
            formData.append("itemTwoQuantity", this.exploreItemTwoQuantity);
            formData.append("itemThreeTitle", this.exploreItemThreeTitle);
            formData.append("itemThreeQuantity", this.exploreItemThreeQuantity);
            formData.append("itemFourTitle", this.exploreItemFourTitle);
            formData.append("itemFourQuantity", this.exploreItemFourQuantity);
            formData.append("itemOneImage", this.itemOneImage);
            formData.append("itemTwoImage", this.itemTwoImage);
            formData.append("itemThreeImage", this.itemThreeImage);
            formData.append("itemFourImage", this.itemFourImage);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.about.updateExploreItemCmsURL,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                this.$refs.itemFourImage.value = null;
                this.$refs.itemThreeImage.value = null;
                this.$refs.itemTwoImage.value = null;
                this.$refs.itemOneImage.value = null;
                this.isLoading = false;
                this.getExploreItemCMS();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}