import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import Mixin from "../../../mixin";
export default {
    name: "ProductSellRequest",
    components: {
        Loading
    },
    mixins: [Mixin],
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            productSellRequestList: [],
            name: "",
            price: 0,
            description: "",
            videoLink: "",
            location: "",
            requestStatus: "",
            selectedCategory: "",
            singleContent: [],
        }
    },
    async created() {
        document.title = "Gun Buy Sell - Product";
        await this.getProductCategoryList();
        this.getProductsSellRequestList();
    },
    methods: {
        getProductsSellRequestList: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.getProductsSellRequestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    console.log(response.data);
                    this.productSellRequestList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        addProductSellRequest: async function() {
            this.isLoading = true;
            let data = {
                name: this.name,
                categoryName: this.selectedCategory.name,
                categoryId: this.selectedCategory.id,
                price: this.price,
                description: this.description,
                videoLink: this.videoLink,
                location: this.location,
                requestStatus: this.requestStatus,
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.product.addProductSellRequestURL,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                this.isLoading = false;
                if (response.status == 201) {
                    this.name = "";
                    this.selectedCategory = "";
                    this.price = "";
                    this.description = "";
                    this.videoLink = "";
                    this.location = "";
                    this.requestStatus = "";

                    this.getProductsSellRequestList();
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully stored data!"
                    });
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        editProductSellRequest: async function(id) {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.getSingleProductSellRequestURL + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.singleContent = response.data;
                    console.log(this.singleContent.name)
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        updateProductSellRequest: async function(id) {
            let data = {
                name: this.singleContent.name,
                price: this.singleContent.price,
                description: this.singleContent.description,
                videoLink: this.singleContent.videoLink,
                location: this.singleContent.location,
                requestStatus: this.requestStatus,
                categoryId: this.singleContent.id,
                categoryName: this.selectedCategory.name,
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.product.updateProductSellRequestURL + id,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.isLoading = false;
                    this.getProductsSellRequestList();
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated!"
                    });
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        // deleteProduct: function(id) {
        //     var answer = window.confirm("Are you sure to delete this category?");
        //     if (answer) {
        //         this.isLoading = true;
        //         let config = {
        //             method: "DELETE",
        //             url: this.$serverURL + this.$api.product.deleteProductURL + id,
        //             headers: authHeader()
        //         };
        //         this.$axios(config).then((response) => {
        //             if(response.data.deleted == true) {
        //                 this.$swal.fire({
        //                     icon: "success",
        //                     text: "Successfully deleted data!"
        //                 });
        //             }
        //             this.isLoading = false;
        //             this.getProductList();
        //         }).catch(error => {
        //             console.log(error);
        //             this.isLoading = false;
        //             this.$swal.fire({
        //                 icon: "error",
        //                 text: "Something went wrong. Please try again!"
        //             });
        //         });
        //     }
        // },
    }
}