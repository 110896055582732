import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "User",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            userList: [],
            roleList: [],
            name: null,
            email: null,
            password: null,
            selectRole: null,
            singleUser: [],
            updateName: null,
            updateEmail: null,
            updatePassword: null,
            singleSelectRole: null,
            userID: null,
            updateSelectRole: "",
            selectedRoleName: ""
        }
    },
    async created() {
        document.title = "Gun Buy Sell - User";
        await this.getRoleList();
        await this.getUserList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getRoleList: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.role.getRoleListURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.roleList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getUserList:async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.getUserListURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.userList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        addUser:async function() {
            this.isLoading = true;
            let data = {
                name: this.name,
                email: this.email,
                password: this.password,
                role: this.selectRole.id
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.user.addUserURL,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully stored data!"
                    });
                    this.name = null;
                    this.email = null;
                    this.password = null;
                    this.selectRole = null;
                }
                this.getUserList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        deleteUser:async function(id) {
            var answer = window.confirm("Are you sure to delete this user?");
            if (answer) {
                this.isLoading = true;
                let config = {
                    method: "DELETE",
                    url: this.$serverURL + this.$api.user.deleteUserURL + id,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully deleted data!"
                        });
                    }
                    this.getUserList();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
        },
        editUser:async function(id) {
            this.isLoading = true;
            this.userID = id;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.getSingleUserURL + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.singleUser = response.data;
                    this.updateName = this.singleUser.name;
                    this.updateEmail = this.singleUser.email;
                    this.updatePassword = this.singleUser.password;
                    this.selectedRoleName = this.singleUser.role;
                    if(this.selectedRoleName == 1) {
                        this.selectedRoleName = "Admin";
                    }
                    else if(this.selectedRoleName == 3) {
                        this.selectedRoleName = "User";
                    }
                    console.log(this.selectedRoleName);
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        updateUser:async function(id) {
            this.isLoading = true;
            let data = {
                name: this.updateName,
                email: this.updateEmail,
                password: this.updatePassword,
                role: this.updateSelectRole.id
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.user.updateUserURL + id,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                this.getUserList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}