import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "TeamPageCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            pageHeaderBackground: null,
            pageHeaderTitle: null,
            pageHeaderPageName: null,
            teamTitle: null,
            teamSubTitle: null,
            uploadFile: null
        }
    },
    created() {
        document.title = "Gun Buy Sell - Our Team Page CMS";
        this.getPageCMS();
    },
    methods: {
        getPageCMS: function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.team.getPageCmsURL,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data[0];
                    this.pageHeaderBackground = this.dataList.headerBackground;
                    this.pageHeaderTitle = this.dataList.headerTitle;
                    this.pageHeaderPageName = this.dataList.headerPageName;
                    this.teamTitle = this.dataList.teamTitle;
                    this.teamSubTitle = this.dataList.teamSubTitle;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onFileChange(event) {
            this.uploadFile = event.target.files[0];
        },
        update: function() {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("headerTitle", this.pageHeaderTitle);
            formData.append("headerPageName", this.pageHeaderPageName);
            formData.append("teamTitle", this.teamTitle);
            formData.append("teamSubTitle", this.teamSubTitle);
            formData.append("headerBackground", this.uploadFile);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.team.updatePageCmsURL,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                    this.$refs.backgroundFile.value = null;
                }
                this.isLoading = false;
                this.getPageCMS();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}