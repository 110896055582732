import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";


export default {
    name: "BlogPostTags",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            blogPostTags:[],
            blogPosts: [],
            blogTags: [],
            singleBlogPostTag:[],
            selectedBlogPost: null,
            selectedBlogTag: null,
            updateBlogPost: "",
            updateBlogTag: "",
            id: ''
        }
    },
    async created() {
        document.title = "Gun Buy Sell - Blog Post Tags";
        await this.getBlogPostTag();
        await this.getBlogPosts();
        await this.getBlogTags();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    watch: {
        // singleBlogPostTag: function() {
        //     this.updateBlogPost = this.singleBlogPostTag.postId
        //     this.updateBlogTag = this.singleBlogPostTag.tagName
        // }
    },
    methods: {
        getBlogPosts: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogPostURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.blogPosts = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getBlogTags: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogTagURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.blogTags = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        addBlogPostTags: function() {
            this.isLoading = true;
            let data = {
                postId: this.selectedBlogPost.id,
                tagId: this.selectedBlogTag.id,
                tagName: this.selectedBlogTag.name,
            };
            console.log(data);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.blog.addBlogPostTagURL,
                data: data,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.isLoading = false;
                    this.getBlogPostTag();
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully stored data!"
                    });
                    this.selectedBlogPost = null;
                    this.selectedBlogTag = null;
                    
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getBlogPostTag: async function(){
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogPostTagURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.blogPostTags = response.data;
                    console.log(this.blogPostTags);
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        editBlogPostTag: async function(id) {
            this.isLoading = true;
            //this.contentID = id;
            this.id = id;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getSingleBlogPostTagURL + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.singleBlogPostTag = response.data;
                    // this.updateBlogPost=,
                    // this.updateBlogTag: null,
                    //console.log(this.singleBlogPostTag.id);
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        updateBlogPostTag: async function() {
            this.isLoading = true;
            //this.contentID = id;
            let data = {
                postId: this.updateBlogPost.id,
                postTitle: this.updateBlogPost.postTitle,
                tagId: this.updateBlogTag.id,
                tagName: this.updateBlogTag.name,
            }
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.blog.getSingleBlogPostTagURL + this.id,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.getBlogPostTag();
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                    this.updateBlogPost = "";
                    this.updateBlogTag = "";
                    this.id = '';
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        deleteBlogPostTag: async function(id){
            var answer = window.confirm("Are you sure to delete this content?");
            if (answer) {
                this.isLoading = true;
                let config = {
                    method: "DELETE",
                    url: this.$serverURL + this.$api.blog.deleteBlogPostTagURL + id,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully deleted data!"
                        });
                    }
                    this.getBlogPostTag();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
        }
    }
}