import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "AboutAssistBlockCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            assistBlockTitle: null,
            assistBlockSubTitle: null,
            assistBlockOneTitle: null,
            assistBlockOneDetails: null,
            assistBlockOneImage: null,
            assistBlockTwoTitle: null,
            assistBlockTwoDetails: null,
            assistBlockTwoImage: null,
            assistBlockThreeTitle: null,
            assistBlockThreeDetails: null,
            assistBlockThreeImage: null,
            blockThreeImage: null,
            blockTwoImage: null,
            blockOneImage: null
        }
    },
    created() {
        document.title = "Gun Buy Sell - About Assist Block CMS";
        this.getAssistBlockCMS();
    },
    methods: {
        getAssistBlockCMS: function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.about.getAssistBlockCmsURL,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data[0];
                    this.assistBlockTitle = this.dataList.blockTitle;
                    this.assistBlockSubTitle = this.dataList.blockSubTitle;
                    this.assistBlockOneTitle = this.dataList.blockOneTitle;
                    this.assistBlockOneDetails = this.dataList.blockOneDetail;
                    this.assistBlockTwoTitle = this.dataList.blockTwoTitle;
                    this.assistBlockTwoDetails = this.dataList.blockTwoDetail;
                    this.assistBlockThreeTitle = this.dataList.blockThreeTitle;
                    this.assistBlockThreeDetails = this.dataList.blockThreeDetail;
                    this.blockOneImage = this.dataList.blockOneImage;
                    this.blockTwoImage = this.dataList.blockTwoImage;
                    this.blockThreeImage = this.dataList.blockThreeImage;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onAssistBlockOneImage(event) {
            this.assistBlockOneImage = event.target.files[0];
        },
        onAssistBlockTwoImage(event) {
            this.assistBlockTwoImage = event.target.files[0];
        },
        onAssistBlockThreeImage(event) {
            this.assistBlockThreeImage = event.target.files[0];
        },
        update: function() {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("blockTitle", this.assistBlockTitle);
            formData.append("blockSubTitle", this.assistBlockSubTitle);
            formData.append("blockOneTitle", this.assistBlockOneTitle);
            formData.append("blockOneDetail", this.assistBlockOneDetails);
            formData.append("blockTwoTitle", this.assistBlockTwoTitle);
            formData.append("blockTwoDetail", this.assistBlockTwoDetails);
            formData.append("blockThreeTitle", this.assistBlockThreeTitle);
            formData.append("blockThreeDetail", this.assistBlockThreeDetails);
            formData.append("blockOneImage", this.assistBlockOneImage);
            formData.append("blockTwoImage", this.assistBlockTwoImage);
            formData.append("blockThreeImage", this.assistBlockThreeImage);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.about.updateAssistBlockCmsURL,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                this.$refs.blockOneImage.value = null;
                this.$refs.blockTwoImage.value = null;
                this.$refs.blockThreeImage.value = null;
                this.isLoading = false;
                this.getAssistBlockCMS();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}