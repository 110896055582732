import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";
import productDetailsModal from "../template/productDetailsModal"
export default {
    name: "PendingListing",
    components: {
        Loading,
        productDetailsModal
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            listingList: [],
            singleProduct: {},
            productData: []
        }
    },
    async created() {
        document.title = "Gun Buy Sell - Pending Listing";
        await this.getlistingList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        
    },
    methods: {
        getlistingList: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.getPendingProductsURL + '?status=Pending',
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.listingList = response.data;
                }
                this.isLoading = false;
                // console.log("response",response)
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        approveProduct: function(index) {
            this.singleProduct = this.listingList[index];
            this.singleProduct.publishStatus = "Approved";
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.product.updateSingleProductURL + this.singleProduct.id,
                data: this.singleProduct,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                this.isLoading = false;
                this.getlistingList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        rejectProduct: function(index) {
            this.singleProduct = this.listingList[index];
            this.singleProduct.publishStatus = "Rejected";
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.product.updateSingleProductURL + this.singleProduct.id,
                data: this.singleProduct,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                this.isLoading = false;
                this.getlistingList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        viewProductInfo: async function(index) {
            this.productData = index;
        }
    }
}