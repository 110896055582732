import { authHeader } from './auth';
import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading
    },
    data() {
        return {
            teamMemberList: [],
            productCategoryList: []
        }
    },
    methods: {
        getTeamMembers: function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.team.getTeamMemberURL,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.teamMemberList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getProductCategoryList: function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.getCategoryListURL,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.productCategoryList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}