import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "BlogComment",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            blogCommentList: []
        }
    },
    async created() {
        document.title = "Gun Buy Sell - Blog Comment";
        await this.getBlogCommentList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getBlogCommentList: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogCommentURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.blogCommentList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        deleteComment: async function(id) {
            let config = {
                method: "DELETE",
                url: this.$serverURL + this.$api.blog.deleteCommentAPI + id,
                headers: authHeader()
            };
            await this.$axios(config)
                .then(async (response) => {
                    if (response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Your Comment has been deleted!",
                        });
                        await this.getBlogCommentList();
                    }
                    else if (response.status == 200){
                        this.$swal.fire({
                            icon: "error",
                            text: "You can not delete this comment",
                        });
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!",
                    });
                });
        },
    }
}