<template>
    <div class="page-wrapper">
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Product</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Listing</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Pending Listing Table</h6><hr/>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="listingList.length > 0">
                                <tr v-for="(row, index) in listingList" :key="index">
                                    <td> {{ index + 1 }} </td>
                                    <td> {{ row['name'] }} </td>
                                    <td> {{ row['publishStatus'] }} </td>
                                    <td> {{ $filters.formatDate(row['createdAt']) }} </td>
                                    <td> 
                                        <div class="d-flex order-actions">
                                            <button type="button" class="btn btn-primary btn-block px-3 mt-2" @click="approveProduct(index)">
                                                Approve
                                            </button>
                                            <button type="button" class="btn btn-danger btn-block px-3 mt-2 ms-2" @click="rejectProduct(index)">
                                                Reject
                                            </button>
                                            <button type="button" class="btn btn-info btn-block px-3 mt-2 ms-2" @click="viewProductInfo(row)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                                View
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="5"><center>No Data Available</center></td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <product-details-modal :productData="productData"></product-details-modal>
</template>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
    background-color: #17a00e;
}
.delete-button {
    background-color: #f41127;
}
</style>

<script src="../js/pending-listing.js"></script>