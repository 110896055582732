<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader"/>
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
            <li class="breadcrumb-item active" aria-current="page">Product Listing</li>
          </ol>
        </nav>
        <div class="ms-auto">
          <!-- Edit Product Modal -->

          <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="editModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="updateProduct(singleContent.id)">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Product</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Name:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleContent.name"
                      placeholder="Product category name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Price:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleContent.price"
                      placeholder="Product category name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Description:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleContent.description"
                      placeholder="Product category name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Video Link:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleContent.videoLink"
                      placeholder="Product category name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Location:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleContent.location"
                      placeholder="Product category name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Product Category:</label>
                    <select v-model="selectedCategory" class="form-select mb-3">
                      <option style="display: none" selected value="">
                        {{ singleContent.category }}
                      </option>
                      <option
                        v-for="(category, index) in productCategoryList"
                        :key="index"
                        :value="{ id: category.id, name: category.name }"
                      >
                        {{ category.name }}
                      </option>
                    </select>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Product Owner:</label>
                    <select v-model="owner" class="form-select mb-3">
                      <option style="display: none" selected value="">
                        {{ singleContent.owner }}
                      </option>
                      <option
                        v-for="(user, index) in teamMemberList"
                        :key="index"
                        :value="{ id: user.id, name: user.name }"
                      >
                        {{ user.name }}
                      </option>
                    </select>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Product State:</label>
                    <select
                      v-model="state"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option style="display: none" selected value="">
                        {{ singleContent.state }}
                      </option>
                      <option value="used">Used</option>
                      <option value="new">New</option>
                    </select>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Product Publish Status:</label>
                    <select
                      v-model="publishStatus"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option style="display: none" selected value="">
                        {{ singleContent.publishStatus }}
                      </option>
                      <option value="request_initiate">Request Initiate</option>
                      <option value="reject">Reject</option>
                      <option value="draft">Draft</option>
                      <option value="pusblish">Publish</option>
                    </select>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- Edit Role Modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Product Listing Table</h6>
      <hr/>
      <div class="card">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-sm-12 col-md-2">
              <div class="mb-3">
                <label class="form-label">Category:</label>
                <Select2 v-model="category" :options="categories" :settings="{ settingOption: value, settingOption: value }" @change="myChangeEvent($event)" @select="mySelectEvent($event)"/>
              </div>
            </div>
            <div class="col-sm-12 col-md-3">
              <div class="mb-3">
                <label class="form-label">User:</label>
                <Select2 v-model="user" :options="users" :settings="{ settingOption: value, settingOption: value }" @change="myChangeEvent($event)" @select="mySelectEvent($event)"/>
              </div>
            </div>
            <div class="col-sm-12 col-md-2">
              <div class="mb-3">
                <label class="form-label">Start Date:</label>
                <input type="date" v-model="start_date" class="form-control"/>
              </div>
            </div>
            <div class="col-sm-12 col-md-2">
              <div class="mb-3">
                <label class="form-label">End Date:</label>
                <input type="date" v-model="end_date" class="form-control"/>
              </div>
            </div>
            <div class="col-sm-12 col-md-2 mt-3" >
              <button type="button" @click="searchProducts" class="btn btn-primary px-4 radius-30 ms-2 mt-2" style="float: right; padding-right: 10px;">
                Search
              </button>
            </div>
          </div>
          <hr>
          <div class="table-responsive">
            <table id="example2" class="table table-striped table-bordered" v-if="productList.length > 0">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Is Featured</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="productList.length > 0">
                <tr v-for="(row, index) in productList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ row.name }}</td>
                  <td>{{ row.price }}</td>
                  <td>{{ row.publishStatus }}</td>
                  <td>{{ row.isFeature == 1 ? "Yes" : "No" }}</td>
                  <td>{{ $filters.formatDate(row.createdAt) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <!-- <a href="javascript:void(0);" v-on:click="editProduct(row.id)" data-bs-toggle="modal" data-bs-target="#editModal" class="edit-button"><i class="bx bxs-edit text-white"></i></a> -->
                      <a href="javascript:void(0);" v-on:click="deleteProduct(row.id)" class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td>No data found!</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Is Featured</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>

<script src="../js/product.js"></script>