import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import Multiselect from '@vueform/multiselect';

export default {
    name: "BlogRelatedPost",
    components: {
        Multiselect,
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            blogPosts: [],
            selectedBlogPost: "",
            value: [],
            options: [],
            getBlogRelatePostList: [],
            combinedDataList: [],
            singleBlogRelatePost: []

        }
    },
    async created() {
        document.title = "Gun Buy Sell - Blog Post Tags";
        await this.getBlogPosts();
        await this.getBlogRelatePost();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    watch: {
        // singleBlogPostTag: function() {
        //     this.updateBlogPost = this.singleBlogPostTag.postId
        //     this.updateBlogTag = this.singleBlogPostTag.tagName
        // }
    },
    methods: {
        getBlogPosts: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogPostURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.blogPosts = response.data;
                    this.options = response.data.map((el) => {
                        return { value: el.id, label: el.title }
                    });
                    console.log(this.options);
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        getBlogRelatePost: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.allRelatedPosts,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.getBlogRelatePostList = response.data;
                    this.getBlogRelatePostList.map((el1) => {
                        let data = [];
                        let relatedPost = [];
                        let separatedArray = el1.relatedPostId.split(', ');
                        this.blogPosts.map((el2) => {
                            if (el1.postId == el2.id) {
                                console.log("true");
                                data.push({ title: el2.title })
                            }
                        });
                        separatedArray.map((el3) => {
                            this.blogPosts.map((el4) => {
                                if (el3 == el4.id) {
                                    relatedPost.push({ title: el4.title })
                                }
                            });
                            data.push(relatedPost);
                        })

                        this.combinedDataList.push(data);
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        addContent: async function() {
            this.isLoading = true;
            let data = {
                postId: this.selectedBlogPost.id,
                relatedPostId: this.value,
            };
            //let updatedData = JSON.stringify(data)
            console.log(data);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.blog.addBlogRelatePostURL,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 201) {
                    this.isLoading = false;
                    this.selectedBlogPost = "",
                        this.value = [],
                        this.getBlogRelatePost();
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully stored data!"
                    });
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        // getBlogPostTag: async function(){
        //     this.isLoading = true;
        //     let config = {
        //         method: "GET",
        //         url: this.$serverURL + this.$api.blog.getBlogPostTagURL,
        //         headers: authHeader()
        //     };
        //     await this.$axios(config).then((response) => {
        //         if(response.status == 200) {
        //             this.blogPostTags = response.data;
        //             console.log(this.blogPostTags);
        //         }
        //         this.isLoading = false;
        //     }).catch(error => {
        //         console.log(error);
        //         this.isLoading = false;
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Something went wrong. Please try again!"
        //         });
        //     });
        // },

        // editBlogPostTag: async function(id) {
        //     this.isLoading = true;
        //     //this.contentID = id;
        //     let config = {
        //         method: "GET",
        //         url: this.$serverURL + this.$api.blog.getSingleBlogPostTagURL + id,
        //         headers: authHeader()
        //     };
        //     await this.$axios(config).then((response) => {
        //         if(response.status == 200) {
        //             this.singleBlogPostTag = response.data;
        //             // this.updateBlogPost=,
        //             // this.updateBlogTag: null,
        //             //console.log(this.singleBlogPostTag.id);
        //         }
        //         this.isLoading = false;
        //     }).catch(error => {
        //         console.log(error);
        //         this.isLoading = false;
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Something went wrong. Please try again!"
        //         });
        //     });
        // },

        deleteRelatedPost: async function(id){
            var answer = window.confirm("Are you sure to delete this content?");
            if (answer) {
                this.isLoading = true;
                let config = {
                    method: "DELETE",
                    url: this.$serverURL + this.$api.blog.detailsBlogRelatePostURL + id,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully deleted data!"
                        });
                    }
                    this.getBlogRelatePost();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
        }
    }
}