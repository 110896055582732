import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
export default {
    name: "Order",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            order_list: []
        }
    },
    async mounted() {
        await this.getInvoiceList();

    },
    created() {
        document.title = "Gun Buy Sell - Order";
    },
    methods: {
        invoice: function(id) {
            this.$router.push({
                name: "Invoice",
                params: { id: id }
            });
        },
        getInvoiceList: function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.order.invoiceList,
                headers: authHeader()
            }
            this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.order_list = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }

}