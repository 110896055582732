<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">About Us</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">About Explore Item CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body p-4">
                    <h5 class="card-title">About Explore Item CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Explore Item One Title</label>
                                        <input type="text" v-model="exploreItemOneTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Explore Item One Quantity</label>
                                        <input type="text" v-model="exploreItemOneQuantity" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="form-label">Explore Item One Image</label>
                                                <input class="form-control" type="file" @change="onItemOneImage" ref="itemOneImage">
                                            </div>
                                            <div class="col-md-6">
                                                <img :src="$imageURL + 'about/' + imageOne" height="80" width="80">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Explore Item Two Title</label>
                                        <input type="text" v-model="exploreItemTwoTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Explore Item Two Quantity</label>
                                        <input type="text" v-model="exploreItemTwoQuantity" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="form-label">Explore Item Two Image</label>
                                                <input class="form-control" type="file" @change="onItemTwoImage" ref="itemTwoImage">
                                            </div>
                                            <div class="col-md-6">
                                                <img :src="$imageURL + 'about/' + imageTwo" height="80" width="80">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Explore Item Three Title</label>
                                        <input type="text" v-model="exploreItemThreeTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Explore Item Three Quantity</label>
                                        <input type="text" v-model="exploreItemThreeQuantity" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="form-label">Explore Item Three Image</label>
                                                <input class="form-control" type="file" @change="onItemThreeImage" ref="itemThreeImage">
                                            </div>
                                            <div class="col-md-6">
                                                <img :src="$imageURL + 'about/' + imageThree" height="80" width="80">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Explore Item Four Title</label>
                                        <input type="text" v-model="exploreItemFourTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Explore Item Four Quantity</label>
                                        <input type="text" v-model="exploreItemFourQuantity" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="form-label">Explore Item Four Image</label>
                                                <input class="form-control" type="file" @change="onItemFourImage" ref="itemFourImage">
                                            </div>
                                            <div class="col-md-6">
                                                <img :src="$imageURL + 'about/' + imageFour" height="80" width="80">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/about-explore-item-cms.js"></script>