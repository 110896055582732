import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
export default {
    name: "Invoice",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            invoice_details: {},
            user_info: {},
            invoice_items: [],
            grand_total: 0,
        }
    },
    mounted() {
        this.getInvoiceDetails(this.$route.params.id);

    },
    created() {
        document.title = "Gun Buy Sell - Order";
    },
    methods: {
        getInvoiceDetails: function(id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.order.invoiceDetails + id,
                headers: authHeader()
            }
            this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.invoice_details = response.data;
                    this.invoice_items = response.data.invoiceItem;
                    this.user_info = response.data.user;
                    let total = 0;
                    for (const key in Object.keys(response.data.invoiceItem)) {
                        total = total + parseFloat(response.data.invoiceItem[key].productTotalPrice);
                    }
                    this.grand_total = total;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }

}