<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Blog Post</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Blog Post
              </li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <!-- <button
              type="button"
              class="btn btn-primary mr-1 ml-1"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <i class="bx bxs-plus-square"></i>Add New Blog Post Modal
            </button> | -->
            <button type="button" class="btn btn-primary" @click="$router.push('/blog-new-post')">
              <i class="bx bxs-plus-square"></i>Add New Blog Post Form
            </button>
          </div>
          <!-- Add Content Modal -->
          <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
            <form @submit.prevent="addContent" enctype="multipart/form-data">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">
                      Add New Blog Post
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Post Title:</label>
                    <input class="form-control mb-3" type="text" v-model="postTitle" placeholder="blog post title"
                      aria-label="default input example" />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Short Details:</label>
                    <textarea class="form-control" v-model="postShortDetails" placeholder="short details"
                      rows="3"></textarea>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Long Details:</label>
                    <textarea class="form-control" v-model="postLongDetails" placeholder="long details"
                      rows="3"></textarea>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Upload Post Image:</label>
                    <input class="form-control" type="file" id="formFile"
                      accept="image/gif, image/jpeg, image/png, image/svg" @change="onPostImageChange" />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Total Comments:</label>
                    <input class="form-control mb-3" type="text" v-model="totalComment" placeholder="total comments"
                      aria-label="default input example" />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Category Name:</label>
                    <select v-model="selectedCategory" class="form-select mb-3">
                      <option v-for="(category, index) in blogCategory" :key="index"
                        :value="{ id: category.id, name: category.name }">
                        {{ category.name }}
                      </option>
                    </select>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Posted By:</label>
                    <input class="form-control mb-3" type="text" v-model="postedBy" placeholder="total comments"
                      aria-label="default input example" />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Uploader Image:</label>
                    <input class="form-control" type="file" id="formFile"
                      accept="image/gif, image/jpeg, image/png, image/svg" @change="onUploaderImageChange" />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Blog Tag:</label>
                    <select v-model="selectedBlogTag" class="form-select mb-3">
                      <option v-for="(tag, index) in blogTags" :key="index" :value="{ id: tag.id, name: tag.name }">
                        {{ tag.name }}
                      </option>
                    </select>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                      Close
                    </button>
                    <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Add Content Modal -->
          <!-- Edit Content Modal -->
          <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
            <form @submit.prevent="updateContent(singleContent.id)">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="editModalLabel">
                      Edit Content
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Post Title:</label>
                    <input class="form-control mb-3" type="text" v-model="updatePostTitle" placeholder="blog post title"
                      aria-label="default input example" />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Short Details:</label>
                    <Editor api-key="44lamukdf4uvndgqk5nzsrlp6sx2r5yy35eokwtslcnqpmwl"
                      v-model="updatePostShortDetails" />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Long Details:</label>

                    <Editor api-key="44lamukdf4uvndgqk5nzsrlp6sx2r5yy35eokwtslcnqpmwl"
                      v-model="updatePostLongDetails" />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Upload Post Image:</label>
                    <input class="form-control" type="file" id="formFile"
                      accept="image/gif, image/jpeg, image/png, image/svg" @change="onPostImageChange" />
                  </div>

                  <div class="modal-body">
                    <label class="form-label">Category</label>
                    <select v-model="updateSelectedCategory" class="form-select mb-3">
                      <option style="display: none" selected value="">
                        {{ singleContent.categoryName }}
                      </option>
                      <option v-for="(category, index) in blogCategory" :key="index"
                        :value="{ id: category.id, name: category.name }">
                        {{ category.name }}
                      </option>
                    </select>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Posted By:</label>
                    <input class="form-control mb-3" type="text" v-model="updatePostedBy" placeholder="total comments"
                      aria-label="default input example" />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Uploader Image:</label>
                    <input class="form-control" type="file" id="formFile"
                      accept="image/gif, image/jpeg, image/png, image/svg" @change="onUploaderImageChange" />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Blog Tag:</label>
                    <select v-model="updateSelectedBlogTag" class="form-select mb-3">
                      <option style="display: none" selected value="">
                        {{ singleContent.tags }}
                      </option>
                      <option v-for="(tag, index) in blogTags" :key="index" :value="{ id: tag.id, name: tag.name }">
                        {{ tag.name }}
                      </option>
                    </select>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                      Close
                    </button>
                    <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Edit Content Modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Blog Post List Table</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Title</th>
                  <th>Category</th>
                  <th>Blog Photo</th>
                  <th>Posted By</th>
                  <th>Uploader Photo</th>
                  <!-- <th>Total Comments</th> -->
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in blogList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ row.title }}</td>
                  <td>{{ row.categoryName }}</td>
                  <td v-if="row.photo != null">
                    <img :src="$imageURL + 'blog/' + row.photo" height="50" width="80" />
                  </td>
                  <td v-else>
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcs7QeC2_kP0lJEj7Q25mpHyeNkLt_oQ43uP2_jLnhozFShnw-Mba_ataiwQd_W1aByyU&usqp=CAU"
                      alt="Girl in a jacket" width="50" height="80" />
                  </td>
                  <td>{{ row.postedBy }}</td>
                  <td v-if="row.photo != null">
                    <img :src=" $imageURL + 'blog/'+ row.UploaderPhoto" height="50" width="80" />
                  </td>
                  <td v-else>
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcs7QeC2_kP0lJEj7Q25mpHyeNkLt_oQ43uP2_jLnhozFShnw-Mba_ataiwQd_W1aByyU&usqp=CAU"
                      alt="Girl in a jacket" width="50" height="80" />
                  </td>
                  <!-- <td>{{ row.totalComments }}</td> -->
                  <td>{{ $filters.formatDateTwo(row['createdAt']) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <router-link :to="{
                                          name: 'BlogEditPost',
                                          params: {id: row.id}
                                      }" class="edit-button"><i class="bx bxs-edit text-white"></i>
                      </router-link>
                      <a href="javascript:void(0);" v-on:click="deleteBlogPost(row.id)" class="ms-3 delete-button"><i
                          class="bx bxs-trash text-white"></i></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Post Title</th>
                  <th>Category Name</th>
                  <th>Blog Photo</th>
                  <th>Posted By</th>
                  <th>Blog Uploader Photo</th>
                  <!-- <th>Total Comments</th> -->
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  @import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";

  .edit-button {
    background-color: #17a00e;
  }

  .delete-button {
    background-color: #f41127;
  }
</style>
<script src="../js/blog-post.js"></script>