import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "RoleList",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            roleList: [],
            roleName: null,
            singleRole: []
        }
    },
    async created() {
        document.title = "Gun Buy Sell - Role Management";
        await this.getRoleList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });        
    },
    methods: {
        getRoleList:async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.role.getRoleListURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.roleList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        addRole: async function() {
            this.isLoading = true;
            let data = {
                name: this.roleName
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.role.addRoleURL,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully stored data!"
                    });
                    this.roleName = "";
                }
                this.isLoading = false;
                this.getRoleList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        deleteRole: function(id) {
            var answer = window.confirm("Are you sure to delete this role?");
            if (answer) {
                this.isLoading = true;
                let config = {
                    method: "DELETE",
                    url: this.$serverURL + this.$api.role.deleteRoleURL + id,
                    headers: authHeader()
                };
                this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully deleted data!"
                        });
                    }
                    this.isLoading = false;
                    this.getRoleList();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
        },
        setId: function(index) {
            this.singleRole = this.roleList[index];
        },
        updateRole: function(id) {
            this.isLoading = true;
            let data = {
                name: this.singleRole.name
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.role.updateRoleURL + id,
                data: data,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                this.isLoading = false;
                this.getRoleList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}