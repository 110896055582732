import { createApp } from 'vue';
import App from './App.vue';
const app = createApp(App);

/* Vue Router */
import router from './routes/routes';
/* Vue Router */

/* Vue Axios */
import axios from 'axios';
import VueAxios from 'vue-axios';

app.use(VueAxios, axios);
app.config.globalProperties.$axios = axios;
/* Vue Axios */

/* Vue Sweetalert */
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

app.use(VueSweetalert2);
/* Vue Sweetalert */

/* Vue Moment */
import moment from 'moment';

app.config.globalProperties.$filters = {
    formatDate(date) {
        return moment(String(date)).format('DD/MM/YYYY hh:mm:ss A');
    },
    formatDateTwo(date) {
        return moment(String(date)).format('YYYY-MM-DD');
    },
};
/* Vue Moment */

/* Vue Toaster */
import Toaster from "@meforma/vue-toaster";

app.use(Toaster, {
    position: "top",
});
/* Vue Toaster */

/* Vue Prototype Declaration */
app.config.globalProperties.$api = require('./config/api.json');

if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    //202.133.91.38
    app.config.globalProperties.$hostURL = 'http://localhost:8080/#';
    app.config.globalProperties.$serverURL = 'http://192.168.5.251:3000/';
    app.config.globalProperties.$imageURL = 'http://192.168.5.251:3000/';
    // app.config.globalProperties.$hostURL = 'http://2ndalist.com/';
    // app.config.globalProperties.$serverURL = 'http://2ndalist.com:3030/';
    // app.config.globalProperties.$imageURL = 'http://2ndalist.com/api/uploads/';
} else {
    app.config.globalProperties.$hostURL = 'https://admin.2ndalist.com/';
    app.config.globalProperties.$serverURL = 'https://2ndalist.com:3030/';
    app.config.globalProperties.$imageURL = 'https://2ndalist.com:3030/';
}
/* Vue Prototype Declaration */

app.use(router).mount('#app')
