import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import Editor from '@tinymce/tinymce-vue';
import Multiselect from '@vueform/multiselect';

//import * as $ from "jquery";
export default {
    name: "BlogEditPost",
    components: {
        Loading,
        Editor,
        Multiselect
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            blogTags: "",
            blogCategory: "",
            //add
            blogList: [],
            postTitle: null,
            postShortDetails: null,
            postLongDetails: null,
            totalComment: 0,
            postedBy: localStorage.getItem('name'),
            selectedCategory: {},
            selectedBlogTag: [],
            selectedUploaderImage: null,
            selectedPostImage: null,
            options: [],
            //update
            singleContent: [],
            updatePostTitle: null,
            updatePostShortDetails: null,
            updatePostLongDetails: null,
            udateTotalComment: null,
            updatePostedBy: localStorage.getItem('name'),
            updateSelectedCategory: "",
            updateSelectedBlogTag: "",
            current_post: {},
            selectedRelatedPosts: [],
            blogId: null,
            relatedOptions: [],
            selectedtags: [],
            blogRelatedPosts: [],
            currentPost: {},
            isPopular: false,
            popularStatus: null

        }
    },
    async created() {

        document.title = "Gun Buy Sell - Blog Post";
        this.blogId = this.$route.params.id;
        await this.getBlogData();
        await this.getBlogList();
        await this.getBlogCategory();
        await this.getBlogTags();
        var scripts = [
            "/core/assets/js/jquery.min.js",
            "/core/assets/plugins/simplebar/js/simplebar.min.js",
            "/core/assets/plugins/metismenu/js/metisMenu.min.js",
            "./core/assets/plugins/perfect-scrollbar/js/perfect-scrollbar.js",
            "/core/assets/plugins/smart-wizard/js/jquery.smartWizard.min.js",
            "/core/assets/js/form-wizard.js", //end wizard js & start editor js
            // "/core/assets/plugins/select2/js/select2.min.js",
            // "/core/assets/js/form-select2.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });


    },
    methods: {
        addTags: function() {
            console.log("This Selected tags: ");
            // console.log("This Selected tags: ", event.target.value);
        },
        TestEditorData: function() {
            console.log('postShortDetails = ', this.postShortDetails);
            console.log('postLongDetails = ', this.postLongDetails);
        },
        getBlogList: function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogPostURL,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.blogList = response.data;
                    this.relatedOptions = response.data.map((el) => {
                        return { value: el.id, label: el.title }
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getBlogData: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getSingleBlogPostURL + this.blogId,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.singleContent = response.data;
                    this.currentPost = this.singleContent;
                    let relatedPosts= response.data.blogRelatedPost;
                    this.isPopular = this.singleContent.isPopular == 1 ? true : false;
                    this.singleContent.category = {
                        id: response.data.categoryId,
                        name: response.data.categoryName
                    };

                    let tagData=response.data.tags;

                    for (let key=0; key<tagData.length; key++){
                        let tagID = tagData[key].tagId;
                        this.selectedBlogTag.push(
                            tagID     
                        );
                    }
                    for (let key=0; key<relatedPosts.length; key++){
                        let postID = relatedPosts[key].relatedPostDetails.id;
                        
                        this.selectedRelatedPosts.push(
                            postID
                        );
                    }
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        getBlogCategory: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogCategoryURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.blogCategory = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        getBlogTags: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogTagURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.blogTags = response.data;
                    this.options = response.data.map((el) => {
                        return { value: el.id, label: el.name }
                    });
                    console.log("Tag Options: ", this.options);
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        onPostImageChange(event) {
            this.selectedPostImage = event.target.files[0];
        },
        onUploaderImageChange(event) {
            this.selectedUploaderImage = event.target.files[0];
        },

      
        createPost: async function() {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("title", this.postTitle);
            formData.append("shortDetail", this.postShortDetails);
            formData.append("longDetail", this.postLongDetails);
            formData.append("categoryId", this.selectedCategory.id);
            formData.append("categoryName", this.selectedCategory.name);
            formData.append("postedBy", this.postedBy);
            formData.append("tags", '');
            formData.append("totalComments", this.totalComment);
            formData.append("photo", this.selectedPostImage);
            formData.append("UploaderPhoto", this.selectedUploaderImage);
            //console.log(formData);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.blog.addBlogPostURL,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 201) {
                    this.isLoading = false;
                    this.current_post = {
                        id: response.data.id,
                        title: response.data.title
                    }

                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully stored data!"
                    });
                    this.postTitle = "";
                    this.postShortDetails = "";
                    this.postLongDetails = "";
                    this.selectedCategory = "";
                    this.totalComment = "";
                    this.selectedPostImage = "";
                    this.selectedUploaderImage = "";
                }
                this.getBlogList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
            let tag_data = {};
            console.log("Tags are: ", this.selectedBlogTag);
            for (const key in Object.keys(this.selectedBlogTag)) {
                tag_data = {
                    postId: this.current_post.id,
                    postTitle: this.current_post.title,
                    tagId: this.selectedBlogTag[key].id,
                    tagName: this.selectedBlogTag[key].name
                };
                console.log("Tag data is: ", tag_data);
                let related_post_config = {
                    url: this.$serverURL + this.$api.blog.addBlogPostTagURL,
                    method: "POST",
                    data: tag_data,
                    headers: authHeader()
                };
                await this.$axios(related_post_config).then((response) => {
                    if (response.status == 201) {
                        this.isLoading = false;
                    }
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Blog Post Tags added Failed!"
                    });
                });
            }
            let related_post_data = {
                postId: this.current_post.id,
                postTitle: this.current_post.title,
                relatedPostId: this.selectedRelatedPost.id,
                relatedPostTitle: this.selectedRelatedPost.name,

            };
            console.log("Tag data is: ", tag_data);
            let related_post_config = {
                url: this.$serverURL + this.$api.blog.addBlogRelatePostURL,
                method: "POST",
                data: related_post_data,
                headers: authHeader()
            };
            await this.$axios(related_post_config).then((response) => {
                if (response.status == 201) {
                    this.isLoading = false;
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Related Blog Post added Failed!"
                });
            });
        },


        updatePost : async function() {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            this.popularPostStatus = this.isPopular ? 1 : 0;
            const formData = new FormData();
            formData.append("title", this.singleContent.title);
            formData.append("shortDetail", this.singleContent.shortDetail);
            formData.append("longDetail", this.singleContent.longDetail);
            formData.append("categoryId", this.singleContent.category.id);
            formData.append("categoryName", this.singleContent.category.name);
            formData.append("isPopular", this.popularPostStatus);
            formData.append("postedBy", this.singleContent.postedBy);
            formData.append("tags", '');
            formData.append("totalComments", this.singleContent.totalComments);
            formData.append("photo", this.selectedPostImage);
            formData.append("UploaderPhoto", this.selectedUploaderImage);
            formData.append("relatedPosts", this.selectedRelatedPosts);
            formData.append("blogTag", this.selectedBlogTag);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.blog.getSingleBlogPostURL + this.blogId,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 201) {
                    this.isLoading = false;
                    
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully stored data!"
                    });
                    this.postTitle = "";
                    this.postShortDetails = "";
                    this.postLongDetails = "";
                    this.selectedCategory = "";
                    this.totalComment = "";
                    this.selectedPostImage = "";
                    this.selectedUploaderImage = "";
                    this.$router.push('/blog-post');
                    this.$swal.fire({
                        icon: "success",
                        text: "Something updated data!"
                    });
                    
                }
                this.getBlogList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
            // let tagData = {};
            // for (const key in Object.keys(this.selectedBlogTag)) {
            //         tagData = {
            //             postId: this.currentPost.id,
            //             postTitle: this.currentPost.title,
            //             tagId: this.selectedBlogTag[key]
            //         };
            //         let relatedTagConfig = {
            //             method: "POST",
            //             url: this.$serverURL + this.$api.blog.addBlogPostTagURL,
            //             data: tagData,
            //             headers: authHeader()
            //         };
            //         await this.$axios(relatedTagConfig).then((response) => {
            //             if (response.status == 201) {
            //                 this.isLoading = false;
            //             }
            //         }).catch(error => {
            //             console.log(error);
            //             this.isLoading = false;
            //             this.$swal.fire({
            //                 icon: "error",
            //                 text: "Blog Post Tags Added Failed!"
            //             });
            //         });
                
            // }
            // this.selectedBlogTag = [];
            // let relatedPostData = {};
            // for (const key in Object.keys(this.selectedRelatedPosts)) {
            //     relatedPostData = {
            //         postId: this.currentPost.id,
            //         postTitle: this.currentPost.title,
            //         relatedPostId: this.selectedRelatedPosts[key],
            //     };
            //     let relatedPostConfig = {
            //         method: "POST",
            //         url: this.$serverURL + this.$api.blog.addBlogRelatePostURL,
            //         data: relatedPostData,
            //         headers: authHeader()
            //     };
            //     await this.$axios(relatedPostConfig).then((response) => {
            //         if (response.status == 201) {
            //             this.isLoading = false;
            //         }
            //     }).catch(error => {
            //         console.log(error);
            //         this.isLoading = false;
            //         this.$swal.fire({
            //             icon: "error",
            //             text: "Related Blog Post Added Failed!"
            //         });
            //     });
            // }
            // this.selectedRelatedPosts = [];
            this.$swal.fire({
                icon: "success",
                text: "Blog info updated successfully."
            });
            this.$router.push('/blog-post');
        },
        
        
    }
}