import { createRouter, createWebHistory } from "vue-router";
import guard from "../guard";
import Login from "../components/auth/template/Login";
// import Signup from "../components/auth/template/Signup";
import Home from "../components/home/template/Home";
import FAQCategory from "../components/faq/template/FAQCategory";
import FAQContent from "../components/faq/template/FAQContent";
import FAQPageCMS from "../components/faq/template/FAQPageCMS";
import TeamMemberData from "../components/our-team/template/TeamMemberData";
import TeamContactData from "../components/our-team/template/TeamContactData";
import TeamPageCMS from "../components/our-team/template/TeamPageCMS";
import ContactData from "../components/contact/template/ContactData";
import ContactPageCMS from "../components/contact/template/ContactPageCMS";
import AboutAssistBlockCMS from "../components/about-us/template/AboutAssistBlockCMS";
import AboutContentCMS from "../components/about-us/template/AboutContentCMS";
import AboutExploreItemCMS from "../components/about-us/template/AboutExploreItemCMS";
import AboutHeaderCMS from "../components/about-us/template/AboutHeaderCMS";
import AboutTeamCMS from "../components/about-us/template/AboutTeamCMS";
import BlogTag from "../components/blog/template/BlogTag";
import BlogCategory from "../components/blog/template/BlogCategory";
import BlogPost from "../components/blog/template/BlogPost";
import BlogNewPost from "../components/blog/template/BlogNewPost";
import BlogEditPost from "../components/blog/template/EditBlogPost";
import BlogPostTags from "../components/blog/template/BlogPostTags";
import BlogRelatedPost from "../components/blog/template/BlogRelatedPost";
import BlogComment from "../components/blog/template/BlogComment";
import BlogPageCMS from "../components/blog/template/BlogPageCMS";
import BlogDetailPageCMS from "../components/blog/template/BlogDetailPageCMS";
import HomeSlider from "../components/home-page/template/HomeSlider";
import HomePageCMS from "../components/home-page/template/HomePageCMS";
import HomeTestimonials from "../components/home-page/template/HomeTestimonials";
// import SliderProductCategory from "../components/home-page/template/SliderProductCategory";
// import HomeFeaturedCategory from "../components/home-page/template/HomeFeaturedCategory";
// import HomeFeaturedProduct from "../components/home-page/template/HomeFeaturedProduct";
// import HomeFeaturedBlog from "../components/home-page/template/HomeFeaturedBlog";
import RoleList from "../components/role/template/RoleList";
import SiteSetting from "../components/site/template/SiteSetting";
import SiteSocialLink from "../components/site/template/SiteSocialLink";
import SiteFooterMenuHeading from "../components/site/template/SiteFooterMenuHeading";
import SiteFooterSubscriber from "../components/site/template/SiteFooterSubscriber";
import SiteModule from "../components/site/template/SiteModule";
// import SitePages from "../components/site/template/SitePages";
import ProductCategory from "../components/product/template/ProductCategory";
import Product from "../components/product/template/Product";
import FeaturedPricing from "../components/product/template/FeaturedPricing";
import AddCategory from "../components/product/template/AddCategory";
import ProductGallery from "../components/product/template/ProductGallery";
import PendingListing from "../components/product/template/PendingListing";
import ApprovedListing from "../components/product/template/ApprovedListing";
import RejectedListing from "../components/product/template/RejectedListing";
import ProductSellRequest from "../components/product/template/ProductSellRequest";
import User from "../components/user/template/User";
import PaymentMethod from "../components/payment/template/PaymentMethod";
// import PaymentHistory from "../components/payment/template/PaymentHistory";
import Dashboard from "../components/dashboard/template/Dashboard";
import Order from "../components/order/template/Order";
import Invoice from "../components/order/template/Invoice";

const routes = [
    /* Auth Routes */
    { path: "/login", name: "Login", component: Login },
    // { path: "/signup", name: "Signup", component: Signup },

    /* Home Route */
    { path: "/", name: "Dashboard", component: Dashboard , beforeEnter: guard},
    { path: "/dashboard", name: "Dashboards", component: Dashboard, beforeEnter: guard },
    { path: "/orders", name: "Order", component: Order, beforeEnter: guard },
    { path: "/invoice/:id", name: "Invoice", component: Invoice, beforeEnter: guard },
    { path: "/home", name: "Home", component: Home, beforeEnter: guard },

    /* FAQ Routes */
    { path: "/faq-category", name: "FAQCategory", component: FAQCategory, beforeEnter: guard },
    { path: "/faq-content", name: "FAQContent", component: FAQContent, beforeEnter: guard },
    { path: "/faq-page-cms", name: "FAQPageCMS", component: FAQPageCMS, beforeEnter: guard },

    /* Our Team Routes */
    { path: "/team-member-data", name: "TeamMemberData", component: TeamMemberData, beforeEnter: guard },
    { path: "/team-contact-data", name: "TeamContactData", component: TeamContactData, beforeEnter: guard },
    { path: "/team-page-cms", name: "TeamPageCMS", component: TeamPageCMS, beforeEnter: guard },

    /* Contact Page Routes */
    { path: "/contact-data", name: "ContactData", component: ContactData, beforeEnter: guard },
    { path: "/contact-page-cms", name: "ContactPageCMS", component: ContactPageCMS, beforeEnter: guard },

    /* About Us Page Routes */
    { path: "/about-header-cms", name: "AboutHeaderCMS", component: AboutHeaderCMS, beforeEnter: guard },
    { path: "/about-content-cms", name: "AboutContentCMS", component: AboutContentCMS, beforeEnter: guard },
    { path: "/about-explore-item-cms", name: "AboutExploreItemCMS", component: AboutExploreItemCMS, beforeEnter: guard },
    { path: "/about-assist-block-cms", name: "AboutAssistBlockCMS", component: AboutAssistBlockCMS, beforeEnter: guard },
    { path: "/about-team-cms", name: "AboutTeamCMS", component: AboutTeamCMS, beforeEnter: guard },

    /* Blog Page Routes */
    { path: "/blog-tag", name: "BlogTag", component: BlogTag, beforeEnter: guard },
    { path: "/blog-category", name: "BlogCategory", component: BlogCategory, beforeEnter: guard },
    { path: "/blog-post", name: "BlogPost", component: BlogPost, beforeEnter: guard },
    { path: "/blog-new-post", name: "BlogNewPost", component: BlogNewPost, beforeEnter: guard },
    { path: "/blog-edit-post/:id", name: "BlogEditPost", component: BlogEditPost, beforeEnter: guard },
    { path: "/blog-post-tags", name: "BlogPostTags", component: BlogPostTags, beforeEnter: guard },
    { path: "/blog-related-post", name: "BlogRelatedPost", component: BlogRelatedPost, beforeEnter: guard },
    { path: "/blog-comment", name: "BlogComment", component: BlogComment, beforeEnter: guard },
    { path: "/blog-page-cms", name: "BlogPageCMS", component: BlogPageCMS, beforeEnter: guard },
    { path: "/blog-detail-page-cms", name: "BlogDetailPageCMS", component: BlogDetailPageCMS, beforeEnter: guard },

    /* Home Page Routes */
    { path: "/home-slider", name: "HomeSlider", component: HomeSlider, beforeEnter: guard },
    { path: "/home-testimonials", name: "HomeTestimonials", component: HomeTestimonials, beforeEnter: guard },
    { path: "/home-page-cms", name: "HomePageCMS", component: HomePageCMS, beforeEnter: guard },
    // { path: "/slider-product-category", name: "SliderProductCategory", component: SliderProductCategory, beforeEnter: guard },
    // { path: "/home-featured-category", name: "HomeFeaturedCategory", component: HomeFeaturedCategory, beforeEnter: guard },
    // { path: "/home-featured-product", name: "HomeFeaturedProduct", component: HomeFeaturedProduct, beforeEnter: guard },
    // { path: "/home-featured-blog", name: "HomeFeaturedBlog", component: HomeFeaturedBlog, beforeEnter: guard },

    /* Site Setting Routes */
    { path: "/site-setting", name: "SiteSetting", component: SiteSetting, beforeEnter: guard },
    { path: "/site-social-link", name: "SiteSocialLink", component: SiteSocialLink, beforeEnter: guard },
    { path: "/site-footer-subscriber", name: "SiteFooterSubscriber", component: SiteFooterSubscriber, beforeEnter: guard },
    { path: "/site-footer-menu-heading", name: "SiteFooterMenuHeading", component: SiteFooterMenuHeading, beforeEnter: guard },
    { path: "/site-module", name: "SiteModule", component: SiteModule, beforeEnter: guard },
    // { path: "/site-pages", name: "SitePages", component: SitePages, beforeEnter: guard },

    /* Role Route */
    { path: "/role-list", name: "RoleList", component: RoleList, beforeEnter: guard },

    /* Product Routes */
    { path: "/product-category", name: "ProductCategory", component: ProductCategory, beforeEnter: guard },
    { path: "/add-category", name: "AddCategory", component: AddCategory, beforeEnter: guard },
    { path: "/product", name: "Product", component: Product, beforeEnter: guard },
    { path: "/featured-pricing", name: "FeaturedPricing", component: FeaturedPricing, beforeEnter: guard },
    { path: "/product-gallery", name: "ProductGallery", component: ProductGallery, beforeEnter: guard },
    { path: "/product-sell-request", name: "ProductSellRequest", component: ProductSellRequest, beforeEnter: guard },
    { path: "/pending-listing", name: "PendingListing", component: PendingListing, beforeEnter: guard },
    { path: "/approved-listing", name: "ApprovedListing", component: ApprovedListing, beforeEnter: guard },
    { path: "/rejected-listing", name: "RejectedListing", component: RejectedListing, beforeEnter: guard },

    /* User Route */
    { path: "/user", name: "User", component: User, beforeEnter: guard },

    /* Payment Routes */
    { path: "/payment-method", name: "PaymentMethod", component: PaymentMethod, beforeEnter: guard },
    // { path: "/payment-history", name: "PaymentHistory", component: PaymentHistory, beforeEnter: guard }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;