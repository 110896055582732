<template>
  <div class="page-wrapper">
    <div class="page-content">
      <!--breadcrumb-->
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">eCommerce</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Orders</li>
            </ol>
          </nav>
        </div>
      </div>
      <!--end breadcrumb-->

      <div class="card">
        <div class="card-body">
          <div class="d-lg-flex align-items-center mb-4 gap-3">
            <div class="position-relative">
              <input
                type="text"
                class="form-control ps-5 radius-30"
                placeholder="Search Order"
              />
              <span
                class="position-absolute top-50 product-show translate-middle-y"
                ><i class="bx bx-search"></i
              ></span>
            </div>
            <div class="ms-auto">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#addModal"
                >
                  <i class="bx bxs-plus-square"></i>Add New Order
                </button>
              </div>
              <div
                class="modal fade"
                id="addModal"
                tabindex="-1"
                aria-labelledby="addModalLabel"
                aria-hidden="true"
              >
                <form @submit.prevent="addProduct">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="addModalLabel">Order</h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Name:</label>
                        <input
                          class="form-control mb-1"
                          type="text"
                          v-model="product_name"
                          placeholder="Product Name"
                          aria-label="default input example"
                        />
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Product Quantity:</label>
                        <input
                          class="form-control mb-1"
                          type="text"
                          v-model="product_quantity"
                          placeholder="Product Description"
                          aria-label="default input example"
                        />
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Total Price:</label>
                        <input
                          class="form-control mb-1"
                          type="text"
                          v-model="price"
                          placeholder="Total Product Price"
                          aria-label="default input example"
                        />
                      </div>
                      <div class="modal-body">
                        <label class="form-label">Customer:</label>
                        <select v-model="customer" class="form-select mb-3">
                          <option style="display: none" selected value="">
                            {{ defaultOwner }}
                          </option>
                          <option
                            v-for="(user, index) in teamMemberList"
                            :key="index"
                            :value="{ id: user.id, name: user.name }"
                          >
                            {{ user.name }}
                          </option>
                        </select>
                      </div>
                     
                      
                      <div class="modal-body">
                        <label class="form-label">Payment Method:</label>
                        <select
                          v-model="payment_method"
                          class="form-select mb-3"
                        >
                          <option style="display: none" selected value="">
                            select payment method
                          </option>
                          <option
                            v-for="(category, index) in productCategoryList"
                            :key="index"
                            :value="{ id: category.id, name: category.name }"
                          >
                            {{ category.name }}
                          </option>
                        </select>
                      </div>
                      <div class="modal-body">
                    <label class="form-label">Remarks:</label>
                    <textarea
                      class="form-control"
                      v-model="remarks"
                      placeholder="Remarks"
                      rows="3"
                    ></textarea>
                    <!-- <textarea id="mytextarea" name="mytextarea">Hello, World!</textarea> -->
                  </div>
                      
                      
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          class="btn btn-primary"
                          data-bs-dismiss="modal"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table mb-0">
              <thead class="table-light">
                <tr>
                  <th>Sl#</th>
                  <th>Product Name</th>
                  <th>Product Total Quantity</th>
                  <th>Product Total Price</th>
                  <th>Payment Method Name</th>
                  <th>Customer Name</th>
                  <th>Payment Status</th>
                  <th>View Details</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in order_list" :key="index">
                  <td>
                    <div class="d-flex align-items-center">
                      <div>
                        <input
                          class="form-check-input me-3"
                          type="checkbox"
                          value=""
                          aria-label="..."
                        />
                      </div>
                      <div class="ms-2">
                        <h6 class="mb-0 font-14">{{ index + 1}}</h6>
                      </div>
                    </div>
                  </td>
                  <td>{{row.productName}}</td>
                  <td>
                    <!-- <div
                      class="
                        badge
                        rounded-pill
                        text-success
                        bg-light-success
                        p-2
                        text-uppercase
                        px-3
                      "
                    >
                      <i class="bx bxs-circle me-1"></i>FulFilled
                    </div> -->
                    {{row.productTotalQuantity}}
                  </td>
                  <td>{{row.productPriceTotal}}</td>
                  <td>{{row.paymentMethodName}}</td>
                  <td>{{row.customerName}}</td>
                  <td>{{row.paymentStatus}}</td>
                  <td>
                    <button
                      @click="invoice(row.id)"
                      type="button"
                      class="btn btn-primary btn-sm radius-30 px-4"
                    >
                      View Details
                    </button>
                  </td>
                  <td>
                    <div class="d-flex order-actions">
                      <a href="javascript:;" class=""
                        ><i class="bx bxs-edit"></i
                      ></a>
                      <a href="javascript:;" class="ms-3"
                        ><i class="bx bxs-trash"></i
                      ></a>
                    </div>
                  </td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/order.js">
</script>