import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import Mixin from "../../../mixin";

export default {
    name: "ProductGallery",
    components: {
        Loading
    },
    mixins: [Mixin],
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            productGalleryList: [],
            selectedProductImage: "",
            singleContent: []
        }
    },
    async created() {
        document.title = "Gun Buy Sell - Product Gallery";
        await this.getProductGalleryList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        
    },
    methods: {
        getProductGalleryList: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.getProductsGalleryURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.productGalleryList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onProductImageChange: function(event) {
            this.selectedProductImage = event.target.files[0];
        },
        setId: function(index) {
            this.singleContent = this.productGalleryList[index];
        },
        editProductGallery: async function(id) {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.getSingleProductGalleryURL + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.singleContent = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        updateProductGallery: async function(id) {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("productId", this.selectedProduct.id);
            formData.append("productName", this.selectedProduct.name);
            formData.append("image", this.selectedProductImage);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.product.updateProductGalleryURL + id,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.isLoading = false;
                    this.getProductGalleryList();
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully Updated data!"
                    });
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        deleteProductGallery: function(id) {
            var answer = window.confirm("Are you sure to delete this data?");
            if (answer) {
                this.isLoading = true;
                let config = {
                    method: "DELETE",
                    url: this.$serverURL + this.$api.product.deleteProductGalleryURL + id,
                    headers: authHeader()
                };
                this.$axios(config).then((response) => {
                    if(response.data.deleted == true) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully deleted data!"
                        });
                        this.getProductGalleryList();
                    }
                    this.isLoading = false;
                    this.getProductList();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
        },
    }
}