import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import Editor from '@tinymce/tinymce-vue';

export default {
    name: "FAQContent",
    components: {
        Loading,
        Editor
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            blogList: [],
            blogTags: "",
            blogCategory: "",
            //add
            postTitle: null,
            postShortDetails: null,
            postLongDetails: null,
            totalComment: null,
            postedBy: localStorage.getItem('name'),
            selectedCategory: null,
            selectedBlogTag: null,
            selectedUploaderImage: null,
            selectedPostImage: null,
            //update
            singleContent:[],
            updatePostTitle: null,
            updatePostShortDetails: null,
            updatePostLongDetails: null,
            udateTotalComment: null,
            updatePostedBy: localStorage.getItem('name'),
            updateSelectedCategory: "",
            updateSelectedBlogTag: "",

        }
    },
    async created() {
        document.title = "Gun Buy Sell - Blog Post";
        await this.getBlogList();
        await this.getBlogCategory();
        await this.getBlogTags();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getBlogList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogPostURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.blogList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        getBlogCategory: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogCategoryURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.blogCategory = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        getBlogTags: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogTagURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.blogTags = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        onPostImageChange(event) {
            this.selectedPostImage = event.target.files[0];
        },
        onUploaderImageChange(event) {
            this.selectedUploaderImage = event.target.files[0];
        },

        addContent: async function () {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("title", this.postTitle);
            formData.append("shortDetail", this.postShortDetails);
            formData.append("longDetail", this.postLongDetails);
            formData.append("categoryId", this.selectedCategory.id);
            formData.append("categoryName", this.selectedCategory.name);
            formData.append("postedBy", this.postedBy);
            formData.append("tags", this.selectedBlogTag.name);
            formData.append("totalComments", this.totalComment);
            formData.append("photo", this.selectedPostImage);
            formData.append("UploaderPhoto", this.selectedUploaderImage);
            //console.log(formData);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.blog.addBlogPostURL,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 201) {
                    this.isLoading = false;
                    
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully stored data!"
                    });
                    this.postTitle = "";
                    this.postShortDetails = "";
                    this.postLongDetails = "";
                    this.selectedCategory="";
                    this.selectedBlogTag="";
                    this.totalComment="";
                    this.selectedPostImage="";
                    this.selectedUploaderImage="";
                }
                this.getBlogList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        editContent: function(id) {
            this.isLoading = true;
            this.contentID = id;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getSingleBlogPostURL + id,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    console.log(response.data);
                    this.singleContent = response.data;
                    this.updatePostTitle = this.singleContent.title;
                    this.updatePostShortDetails = this.singleContent.shortDetail;
                    this.updatePostLongDetails = this.singleContent.longDetail;
                    this.udateTotalComment = this.singleContent.totalComments;
                    this.updatePostedBy = this.singleContent.postedBy;
                    //this.updateSelectedCategory = this.singleContent.categoryName;
                    // this.updateSelectedBlogTag = this.singleContent.faqCategoryId;
                    // this.updateSelectedUploaderImage = this.singleContent.faqCategoryId;
                    // this.updateSelectedPostImage = this.singleContent.faqCategoryId;


                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        updateContent: async function(id) {
            console.log("Content id: ", id);
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("title", this.updatePostTitle);
            formData.append("shortDetail", this.updatePostShortDetails);
            formData.append("longDetail", this.updatePostLongDetails);
            formData.append("categoryId", this.updateSelectedCategory.id);
            formData.append("categoryName", this.updateSelectedCategory.name);
            formData.append("postedBy", this.updatePostedBy);
            formData.append("tags", this.updateSelectedBlogTag.name);
            formData.append("totalComments", this.udateTotalComment);
            formData.append("photo", this.selectedPostImage);
            formData.append("UploaderPhoto", this.selectedUploaderImage);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.blog.updateBlogPostURL + id,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.isLoading = false;
                    
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated!"
                    });
                }
                this.getBlogList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });

        },

        deleteBlogPost: function (id) {
            var answer = window.confirm("Are you sure to delete this content?");
            if (answer) {
                this.isLoading = true;
                let config = {
                    method: "DELETE",
                    url: this.$serverURL + this.$api.blog.deleteBlogPostURL + id,
                    headers: authHeader()
                };
                this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully deleted data!"
                        });
                    }
                    this.getBlogList();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
        },
    }
}