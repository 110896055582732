<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader"/>
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Home Testimonial</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Home Testimonial
              </li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
              <i class="bx bxs-plus-square"></i>Add New Home Testimonial
            </button>
          </div>
          <!-- Add Modal -->
          <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
            <form @submit.prevent="addContent" enctype="multipart/form-data">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">
                      Add Home Testimonial
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Name:</label>
                    <input class="form-control mb-3" type="text" v-model="name" aria-label="default input example"/>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Designation:</label>
                    <input class="form-control mb-3" type="text" v-model="designation" aria-label="default input example"/>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Rating:</label>
                    <input class="form-control mb-3" type="text" v-model="rating" aria-label="default input example"/>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Photo:</label>
                    <input class="form-control" type="file" id="formFile" ref="photoFile" accept="image/gif, image/jpeg, image/png, image/svg" @change="onPhotoChange"/>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Feedback Details:</label>
                    <textarea class="form-control" v-model="feedbackDetail" rows="3"></textarea>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Social Icon:</label>
                    <input class="form-control" type="file" id="formFile" ref="socialIconFile" accept="image/gif, image/jpeg, image/png, image/svg" @change="onSocialIconChange"/>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Social Link:</label>
                    <input class="form-control mb-3" type="text" v-model="socialLink" aria-label="default input example"/>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Save</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Add Modal -->
          <!-- Edit Modal -->
          <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
            <form @submit.prevent="updateContent(singleContent.id)" enctype="multipart/form-data">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Edit Home Testimonial</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Name:</label>
                    <input class="form-control mb-3" type="text" v-model="singleContent.name" aria-label="default input example"/>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Designation:</label>
                    <input class="form-control mb-3" type="text" v-model="singleContent.designation" aria-label="default input example"/>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Rating:</label>
                    <input class="form-control mb-3" type="text" v-model="singleContent.rating" aria-label="default input example"/>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-md-8">
                        <label class="form-label">Photo:</label>
                        <input class="form-control" type="file" id="formFile" accept="image/gif, image/jpeg, image/png, image/svg" @change="onPhotoChange"/>
                      </div>
                      <div class="col-md-4">
                        <img :src="$imageURL + 'home/' + singleContent.photo" height="80" width="80">
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Feedback Details:</label>
                    <textarea class="form-control" v-model="singleContent.feedbackDetail" rows="3"></textarea>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-md-8">
                        <label class="form-label">Social Icon:</label>
                        <input class="form-control" type="file" id="formFile" accept="image/gif, image/jpeg, image/png, image/svg" @change="onSocialIconChange"/>
                      </div>
                      <div class="col-md-4">
                        <img :src="$imageURL + 'home/' + singleContent.socialIcon" height="80" width="80">
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Social Link:</label>
                    <input class="form-control mb-3" type="text" v-model="singleContent.socialLink" aria-label="default input example"/>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Save</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Edit Modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Home Testimonial List Table</h6>
      <hr/>
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Designation</th>
                  <th>Photo</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in dataList" :key="index">
                  <td> {{ index + 1 }} </td>
                  <td> {{ row.name }} </td>
                  <td> {{ row.designation }} </td>
                  <td> <img :src="$imageURL + 'home/' + row.photo" height="50"> </td>
                  <td> {{ $filters.formatDate(row.createdAt) }} </td>
                  <td>
                    <div class="d-flex order-actions">
                      <a href="javascript:void(0);" v-on:click="editContent(row.id)" data-bs-toggle="modal" data-bs-target="#editModal" class="edit-button"><i class="bx bxs-edit"></i></a>
                      <a href="javascript:void(0);" v-on:click="deleteContent(row['id'])" class="ms-3 delete-button"><i class="bx bxs-trash"></i></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Designation</th>
                  <th>Photo</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>
<script src="../js/home-testimonials.js"></script>