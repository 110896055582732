import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "ContactPageCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            pageHeaderTitle: null,
            pageHeaderName: null,
            pageHeaderBackground: null,
            pageContactTitle: null,
            pageContactDetails: null,
            pageContactAddress: null,
            pageContactNumber: null,
            pageContactEmail: null,
            pageContactMapAddress: null,
            sendMessageTitle: null,
            sendMessageSubTitle: null,
            sendMessageButtonText: null,
            uploadFile: null
        }
    },
    created() {
        document.title = "Gun Buy Sell - Contact Page CMS";
        this.getPageCMS();
    },
    methods: {
        getPageCMS: function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.contact.getPageCmsURL,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data[0];
                    this.pageHeaderTitle = this.dataList.headerTitle;
                    this.pageHeaderName = this.dataList.headerPageName;
                    this.pageHeaderBackground = this.dataList.headerBackground;
                    this.pageContactTitle = this.dataList.contactTitle;
                    this.pageContactDetails = this.dataList.contactDetail;
                    this.pageContactAddress = this.dataList.contactAddress;
                    this.pageContactNumber = this.dataList.contactNumber;
                    this.pageContactEmail = this.dataList.contactEmail;
                    this.pageContactMapAddress = this.dataList.contactMapAddress;
                    this.sendMessageTitle = this.dataList.sendMessageSubTitle;
                    this.sendMessageSubTitle = this.dataList.sendMessageTitle;
                    this.sendMessageButtonText = this.dataList.sendMessageButtonText;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onFileChange(event) {
            this.uploadFile = event.target.files[0];
        },
        update: function(id) {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("headerTitle", this.pageHeaderTitle);
            formData.append("headerPageName", this.pageHeaderName);
            formData.append("contactTitle", this.pageContactTitle);
            formData.append("contactDetail", this.pageContactDetails);
            formData.append("contactAddress", this.pageContactAddress);
            formData.append("contactNumber", this.pageContactNumber);
            formData.append("contactEmail", this.pageContactEmail);
            formData.append("contactMapAddress", this.pageContactMapAddress);
            formData.append("sendMessageTitle", this.sendMessageTitle);
            formData.append("sendMessageSubTitle", this.sendMessageSubTitle);
            formData.append("sendMessageButtonText", this.sendMessageButtonText);
            formData.append("headerBackground", this.uploadFile);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.contact.updatePageCmsURL,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                    this.$refs.backgroundFile.value = null;
                }
                this.isLoading = false;
                this.getPageCMS();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}