import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import Editor from '@tinymce/tinymce-vue';
import Multiselect from '@vueform/multiselect';

export default {
    name: "BlogNewPost",
    components: {
        Loading,
        Editor,
        Multiselect
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            blogList: [],
            blogTags: "",
            blogCategory: "",
            postTitle: null,
            postShortDetails: null,
            postLongDetails: null,
            selectedCategory: {},
            selectedPostImage: null,
            selectedBlogTag: [],
            selectedRelatedPosts: [],
            selectedUploaderImage: null,
            postedBy: localStorage.getItem('name'),
            totalComment: 0,
            tagOptions: [],
            relatedOptions: [],
            currentPost: {},
            isPopular: false,
            popularPostStatus: 0
        }
    },
    created() {
        document.title = "Gun Buy Sell - Blog Post";
        var scripts = [
            "/core/assets/js/jquery.min.js",
            "/core/assets/plugins/simplebar/js/simplebar.min.js",
            "/core/assets/plugins/metismenu/js/metisMenu.min.js",
            "./core/assets/plugins/perfect-scrollbar/js/perfect-scrollbar.js",
            "/core/assets/plugins/smart-wizard/js/jquery.smartWizard.min.js",
            "/core/assets/js/form-wizard.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        this.getBlogList();
        this.getBlogCategory();
        this.getBlogTags();
    },
    methods: {
        getBlogList: function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogPostURL,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.blogList = response.data;
                    this.relatedOptions = response.data.map((el) => {
                        return { value: el, label: el.title }
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getBlogCategory: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogCategoryURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.blogCategory = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getBlogTags: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogTagURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.blogTags = response.data;
                    this.tagOptions = response.data.map((el) => {
                        return { value: el, label: el.name }
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onPostImageChange(event) {
            this.selectedPostImage = event.target.files[0];
        },
        onUploaderImageChange(event) {
            this.selectedUploaderImage = event.target.files[0];
        },
        createPost: async function() {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            this.popularPostStatus = this.isPopular ? 1 : 0;
            const formData = new FormData();
            formData.append("title", this.postTitle);
            formData.append("shortDetail", this.postShortDetails);
            formData.append("longDetail", this.postLongDetails);
            formData.append("categoryId", this.selectedCategory.id);
            formData.append("categoryName", this.selectedCategory.name);
            formData.append("isPopular", this.popularPostStatus);
            formData.append("postedBy", this.postedBy);
            formData.append("tags", '');
            formData.append("totalComments", this.totalComment);
            formData.append("photo", this.selectedPostImage);
            formData.append("UploaderPhoto", this.selectedUploaderImage);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.blog.addBlogPostURL,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 201) {
                    this.isLoading = false;
                    this.currentPost = {
                        id: response.data.id,
                        title: response.data.title
                    }
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully stored data!"
                    });
                    this.postTitle = "";
                    this.postShortDetails = "";
                    this.postLongDetails = "";
                    this.selectedCategory = "";
                    this.totalComment = "";
                    this.selectedPostImage = "";
                    this.selectedUploaderImage = "";
                }
                this.getBlogList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
            let tagData = {};
            for (const key in Object.keys(this.selectedBlogTag)) {
                tagData = {
                    postId: this.currentPost.id,
                    postTitle: this.currentPost.title,
                    tagId: this.selectedBlogTag[key].id,
                    tagName: this.selectedBlogTag[key].name
                };
                let relatedTagConfig = {
                    method: "POST",
                    url: this.$serverURL + this.$api.blog.addBlogPostTagURL,
                    data: tagData,
                    headers: authHeader()
                };
                await this.$axios(relatedTagConfig).then((response) => {
                    if (response.status == 201) {
                        this.isLoading = false;
                    }
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Blog Post Tags Added Failed!"
                    });
                });
            }
            this.selectedBlogTag = [];
            let relatedPostData = {};
            for (const key in Object.keys(this.selectedRelatedPosts)) {
                relatedPostData = {
                    postId: this.currentPost.id,
                    postTitle: this.currentPost.title,
                    relatedPostId: this.selectedRelatedPosts[key].id,
                    relatedPostTitle: this.selectedRelatedPosts[key].title,
                };
                let relatedPostConfig = {
                    method: "POST",
                    url: this.$serverURL + this.$api.blog.addBlogRelatePostURL,
                    data: relatedPostData,
                    headers: authHeader()
                };
                await this.$axios(relatedPostConfig).then((response) => {
                    if (response.status == 201) {
                        this.isLoading = false;
                    }
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Related Blog Post Added Failed!"
                    });
                });
            }
            this.selectedRelatedPosts = [];
            this.$router.push('/blog-post');
        }
    }
}