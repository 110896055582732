import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "AboutTeamCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            teamBlockHeading: null,
            teamBlockShortDetails: null,
            teamBlockButtonText: null,
            teamBlockBackground: null,
            teamBlockButtonLink: null,
            teamBlockVideoLink: null,
            // teamBlockVideoFile: null,
            backgroundImage: null,
            // videoPhoto: null
        }
    },
    created() {
        document.title = "Gun Buy Sell - About Team CMS";
        this.getTeamCMS();
    },
    methods: {
        getTeamCMS: function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.about.getTeamCmsURL,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data[0];
                    this.teamBlockHeading = this.dataList.heading;
                    this.teamBlockShortDetails = this.dataList.shortDetail;
                    this.teamBlockButtonText = this.dataList.buttonText;
                    this.teamBlockButtonLink = this.dataList.buttonLink;
                    this.teamBlockVideoLink = this.dataList.videoLink;
                    this.backgroundImage = this.dataList.backgroundImage;
                    // this.videoPhoto = this.dataList.videoPhoto;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onTeamBlockBackground(event) {
            this.teamBlockBackground = event.target.files[0];
        },
        // onTeamBlockVideo(event) {
        //     this.teamBlockVideoFile = event.target.files[0];
        // },
        update: function() {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("heading", this.teamBlockHeading);
            formData.append("shortDetail", this.teamBlockShortDetails);
            formData.append("buttonText", this.teamBlockButtonText);
            formData.append("buttonLink", this.teamBlockButtonLink);
            formData.append("videoLink", this.teamBlockVideoLink);
            formData.append("backgroundImage", this.teamBlockBackground);
            // formData.append("videoPhoto", this.teamBlockVideoFile);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.about.updateTeamCmsURL,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                // this.$refs.videoFile.value = null;
                this.$refs.backgroundFile.value = null;
                this.isLoading = false;
                this.getTeamCMS();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}