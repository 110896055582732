import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            name: localStorage.getItem('name'),
            email: localStorage.getItem('email'),
            role: localStorage.getItem('role')
        }
    },
    created() {
        document.title = "Gun Buy Sell - Home";
    },
    methods: {
        logout: function() {
            localStorage.clear("token");
            localStorage.clear("id");
            localStorage.clear("name");
            localStorage.clear("email");
            this.$router.push("/login");
        }
    }
}