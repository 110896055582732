import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "SiteFooterMenuHeading",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            menuOne: null,
            menuTwo: null,
            menuThree: null
        }
    },
    created() {
        document.title = "Gun Buy Sell - Site Footer Menu Heading";
        this.getSiteFooterMenuData();
    },
    methods: {
        getSiteFooterMenuData: function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.site.getSiteFooterMenuURL,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data[0];
                    this.menuOne = this.dataList.menuOne;
                    this.menuTwo = this.dataList.menuTwo;
                    this.menuThree = this.dataList.menuThree;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        update: function() {
            this.isLoading = true;
            let data = {
                menuOne: this.menuOne,
                menuTwo: this.menuTwo,
                menuThree: this.menuThree
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.site.updateSiteFooterMenuURL,
                data: data,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                this.isLoading = false;
                this.getSiteFooterMenuData();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}