<template>
    <div class="page-wrapper">
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Featured Pricing</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Featured Pricing Manage</li>
                        </ol>
                    </nav>
                </div>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
                            <i class="bx bxs-plus-square"></i>Add New Featured Pricing
                        </button>
                    </div>
                    <!-- Add Featured Pricing Modal -->
                    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form>
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Add New Featured Pricing</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Featured Name:</label>
                                        <input class="form-control mb-3" type="text" v-model="featuredName" placeholder="Featured Name" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Featured Days:</label>
                                        <input class="form-control mb-3" type="text" v-model="featuredDays" placeholder="Featured Days" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Featured Price:</label>
                                        <input class="form-control mb-3" type="text" v-model="featuredPrice" placeholder="Featured Price" aria-label="default input example">
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" v-on:click="addFeaturedPricing" class="btn btn-primary" data-bs-dismiss="modal">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Add Featured Pricing Modal -->
                    <!-- Edit Feature dPricing Modal -->
                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
                        <form>
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="editModalLabel">Edit Featured Pricing</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Featured Name:</label>
                                        <input class="form-control mb-3" type="text" v-model="singleFeaturedPricing.featuredName" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Featured Days:</label>
                                        <input class="form-control mb-3" type="text" v-model="singleFeaturedPricing.featuredDays" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Featured Price:</label>
                                        <input class="form-control mb-3" type="text" v-model="singleFeaturedPricing.featuredPrice" aria-label="default input example">
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" v-on:click="updateFeaturedPricing(singleFeaturedPricing.id)" class="btn btn-primary" data-bs-dismiss="modal">Update</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Edit Featured Pricing Modal -->
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Featured Pricing List Table</h6><hr/>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered" v-if="featuredPricingList.length > 0">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Featured Name</th>
                                    <th>Featured Days</th>
                                    <th>Featured Price</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in featuredPricingList" :key="index">
                                    <td> {{ index + 1 }} </td>
                                    <td> {{ row.featuredName }} </td>
                                    <td> {{ row.featuredDays }} </td>
                                    <td> {{ row.featuredPrice }} </td>
                                    <td> {{ $filters.formatDate(row['createdAt']) }} </td>
                                    <td> 
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" v-on:click="setId(index)" data-bs-toggle="modal" data-bs-target="#editModal" class="edit-button"><i class='bx bxs-edit'></i></a>
                                            <a href="javascript:void(0);" v-on:click="deleteFeaturedPricing(row['id'])" class="ms-3 delete-button"><i class='bx bxs-trash'></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Featured Name</th>
                                    <th>Featured Days</th>
                                    <th>Featured Price</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
    background-color: #17a00e;
}
.delete-button {
    background-color: #f41127;
}
</style>

<script src="../js/featured-pricing.js"></script>