<template>
  <div class="page-wrapper">
    <div class="page-content">
      <!--breadcrumb-->
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Applications</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Invoice
              </li>
            </ol>
          </nav>
        </div>
        
      </div>
      <!--end breadcrumb-->
      <div class="card">
        <div class="card-body">
          <div id="invoice">
            <div class="toolbar hidden-print">
              <div class="text-end">
                <button type="button" class="btn btn-dark">
                  <i class="fa fa-print"></i> Print
                </button>
                <button type="button" class="btn btn-danger">
                  <i class="fa fa-file-pdf-o"></i> Export as PDF
                </button>
              </div>
              <hr />
            </div>
            <div class="invoice overflow-auto">
              <div>
                <header>
                  <div class="row">
                    <div class="col">
                      <a href="javascript:;">
                        <img
                          src="../../../../public/core/assets/images/logo-icon.png"
                          width="80"
                          alt=""
                        />
                      </a>
                    </div>
                    <div class="col company-details">
                      <h2 class="name">
                        <a target="_blank" href="javascript:;"> Arboshiki </a>
                      </h2>
                      <div>455 Foggy Heights, AZ 85004, US</div>
                      <div>(123) 456-789</div>
                      <div>company@example.com</div>
                    </div>
                  </div>
                </header>
                <main>
                  <div class="row contacts">
                    <div class="col invoice-to">
                      <div class="text-gray-light">INVOICE TO:</div>
                      <h2 class="to">{{user_info.name}}</h2>
                      <div class="address">
                        {{user_info.phone}}
                      </div>
                      <div class="email">
                        <a :href="'mailto:' + user_info.email">{{user_info.email}}</a>
                      </div>
                    </div>
                    <div class="col invoice-details">
                      <h1 class="invoice-id">INVOICE 3-2-1</h1>
                      <div class="date">Date of Invoice: 01/10/2018</div>
                      <div class="date">Due Date: 30/10/2018</div>
                    </div>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th class="text-left">DESCRIPTION</th>
                        <th class="text-right">Product Price</th>
                        <th class="text-right">Product Quantity</th>
                        <th class="text-right">TOTAL</th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr v-for="(row, index) in invoice_items" :key="index">
                        <td class="no">{{index + 1}}</td>
                        <td class="text-left">
                          <h3>
                            <a target="_blank" href="javascript:;">
                              {{row.productName}}
                            </a>
                          </h3>
                          <!-- <a target="_blank" href="javascript:;">
                            Useful videos
                          </a>
                          to improve your Javascript skills. Subscribe and stay
                          tuned :) -->
                        </td>
                        <td class="unit">${{row.productUnitPrice}}</td>
                        <td class="qty">{{row.productQuantity}}</td>
                        <td class="total">${{row.productTotalPrice}}</td>
                      </tr>
                      
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="2"></td>
                        <td colspan="2">SUBTOTAL</td>
                        <td>${{grand_total}}</td>
                      </tr>
                      <tr>
                        <td colspan="2"></td>
                        <td colspan="2">TAX 25%</td>
                        <td>$1,300.00</td>
                      </tr>
                      <tr>
                        <td colspan="2"></td>
                        <td colspan="2">GRAND TOTAL</td>
                        <td>${{grand_total}}</td>
                      </tr>
                    </tfoot>
                  </table>
                  <div class="thanks">Thank you!</div>
                  <div class="notices">
                    <div>NOTICE:</div>
                    <div class="notice">
                      A finance charge of 1.5% will be made on unpaid balances
                      after 30 days.
                    </div>
                  </div>
                </main>
                <footer>
                  Invoice was created on a computer and is valid without the
                  signature and seal.
                </footer>
              </div>
              <!--DO NOT DELETE THIS div. IT is responsible for showing footer always at the bottom-->
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/invoice.js">
</script>