<template>
    <div class="page-wrapper">
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">FAQ</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">FAQ Content</li>
                        </ol>
                    </nav>
                </div>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
                            <i class="bx bxs-plus-square"></i>Add New Content
                        </button>
                    </div>
                    <!-- Add Content Modal -->
                    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form>
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Add New Content</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Question Title:</label>
                                        <input class="form-control mb-3" type="text" v-model="questionTitle" placeholder="Enter question title" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Question Details:</label>
                                        <textarea class="form-control" v-model="questionDetails" placeholder="Enter question details" rows="3"></textarea>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Category Name:</label>
                                        <select v-model="selectedCategory" class="form-select mb-3">
                                            <option v-for="(category, index) in categoryList" :key="index" :value="{ id: category.id, name: category.name }">
                                                {{ category.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" v-on:click="addContent" class="btn btn-primary" data-bs-dismiss="modal">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Add Content Modal -->
                    <!-- Edit Content Modal -->
                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
                        <form>
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="editModalLabel">Edit Content</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Question Title:</label>
                                        <input class="form-control mb-3" type="text" v-model="updateQuestionTitle" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Question Details:</label>
                                        <textarea class="form-control" v-model="updateQuestionDetails" rows="3"></textarea>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Category Name:</label>
                                        <select v-model="singleSelectedCategory" class="form-select mb-3">
                                            <option style="display: none" selected value="">
                                                {{ updateSelectedCategoryName }}
                                            </option>
                                            <option v-for="(category, index) in categoryList" :key="index" :value="{ id: category.id, name: category.name }">
                                                {{ category.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" v-on:click="updateContent(contentID)" class="btn btn-primary" data-bs-dismiss="modal">Update</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Edit Content Modal -->
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Content List Table</h6><hr/>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Category Name</th>
                                    <th>Question Title</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in contentList" :key="index">
                                    <td> {{ index + 1 }} </td>
                                    <td> {{ row['faqCategoryName'] }} </td>
                                    <td> {{ row['questionTitle'] }} </td>
                                    <td> {{ $filters.formatDate(row['createdAt']) }} </td>
                                    <td> 
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" v-on:click="editContent(row['id'])" data-bs-toggle="modal" data-bs-target="#editModal" class="edit-button"><i class='bx bxs-edit'></i></a>
                                            <a href="javascript:void(0);" v-on:click="deleteContent(row['id'])" class="ms-3 delete-button"><i class='bx bxs-trash'></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Category Name</th>
                                    <th>Question Title</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
    background-color: #17a00e;
}
.delete-button {
    background-color: #f41127;
}
</style>

<script src="../js/faq-content.js"></script>