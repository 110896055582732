import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "AboutContentCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            aboutHeading: null,
            aboutSubHeading: null,
            aboutDetails: null,
            uploadFile: null,
            photo: null
        }
    },
    created() {
        document.title = "Gun Buy Sell - About Content CMS";
        this.getContentCMS();
    },
    methods: {
        getContentCMS: function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.about.getContentCmsURL,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data[0];
                    this.aboutHeading = this.dataList.heading;
                    this.aboutSubHeading = this.dataList.subHeading;
                    this.aboutDetails = this.dataList.detail;
                    this.photo = this.dataList.photo;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onFileChange(event) {
            this.uploadFile = event.target.files[0];
        },
        update: function() {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("heading", this.aboutHeading);
            formData.append("subHeading", this.aboutSubHeading);
            formData.append("detail", this.aboutDetails);
            formData.append("photo", this.uploadFile);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.about.updateContentCmsURL,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                    this.$refs.photoFile.value = null;
                }
                this.isLoading = false;
                this.getContentCMS();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}