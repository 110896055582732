import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "BlogPageCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            headerTitle: null,
            headerPageName: null,
            blogPostShowLength: null,
            categoryHeading: null,
            popularPostHeading: null,
            popularPostShowLength: null,
            tagHeading: null,
            uploadFile: null,
            headerBackgroundFile: null
        }
    },
    created() {
        document.title = "Gun Buy Sell - Blog Page CMS";
        this.getBlogPageCmsData();
    },
    methods: {
        getBlogPageCmsData: function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.blog.getBlogPageCmsURL,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data[0];
                    this.headerTitle = this.dataList.headerTitle;
                    this.headerPageName = this.dataList.headerPageName;
                    this.blogPostShowLength = this.dataList.blogPostShowLength;
                    this.categoryHeading = this.dataList.categoryHeading;
                    this.popularPostHeading = this.dataList.popularPostHeading;
                    this.popularPostShowLength = this.dataList.popularPostShowLength;
                    this.tagHeading = this.dataList.tagHeading;
                    this.headerBackgroundFile = this.dataList.headerBackground;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onFileChange(event) {
            this.uploadFile = event.target.files[0];
        },
        update: function() {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("headerTitle", this.headerTitle);
            formData.append("headerPageName", this.headerPageName);
            formData.append("blogPostShowLength", this.blogPostShowLength);
            formData.append("popularPostHeading", this.popularPostHeading);
            formData.append("categoryHeading", this.categoryHeading);
            formData.append("popularPostShowLength", this.popularPostShowLength);
            formData.append("tagHeading", this.tagHeading);
            formData.append("headerBackground", this.uploadFile);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.blog.updateBlogPageCmsURL,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                this.$refs.backgroundFile.value = null;
                this.isLoading = false;
                this.getBlogPageCmsData();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}