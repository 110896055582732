<template>
    <div class="page-wrapper">
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Our Team</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Team Member Data</li>
                        </ol>
                    </nav>
                </div>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
                            <i class="bx bxs-plus-square"></i>Add New Team Member
                        </button>
                    </div>
                    <!-- Add Modal -->
                    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form>
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Add Team Member</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Name:</label>
                                        <input class="form-control mb-3" type="text" v-model="name" placeholder="Enter name" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Designation:</label>
                                        <input class="form-control mb-3" type="text" v-model="designation" placeholder="Enter designation" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Member Since:</label>
                                        <input class="form-control mb-3" type="text" v-model="memberSince" placeholder="Enter member since" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Photo:</label>
                                        <input class="form-control" type="file" @change="onPhotoChange" ref="photoFile" id="photoFile">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Short Title:</label>
                                        <textarea class="form-control" v-model="shortDetail" placeholder="Enter short title" rows="3"></textarea>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Details:</label>
                                        <textarea class="form-control" v-model="detail" placeholder="Enter details" rows="3"></textarea>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Phone:</label>
                                        <input class="form-control mb-3" type="text" v-model="phone" placeholder="Enter phone" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Email:</label>
                                        <input class="form-control mb-3" type="email" v-model="email" placeholder="Enter email" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Facebook Link:</label>
                                        <input class="form-control mb-3" type="text" v-model="facebookLink" placeholder="Enter facebook link" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Twitter Link:</label>
                                        <input class="form-control mb-3" type="text" v-model="twitterLink" placeholder="Enter twitter link" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Linkedin Link:</label>
                                        <input class="form-control mb-3" type="text" v-model="linkedinLink" placeholder="Enter linkedin link" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Instagram Link:</label>
                                        <input class="form-control mb-3" type="text" v-model="instagramLink" placeholder="Enter instagram link" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Youtube Link:</label>
                                        <input class="form-control mb-3" type="text" v-model="youtubeLink" placeholder="Enter youtube link" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Member Status:</label>
                                        <input class="form-control mb-3" type="text" v-model="memberStatus" placeholder="Enter member since" aria-label="default input example">
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" v-on:click="addTeamMember" class="btn btn-primary" data-bs-dismiss="modal">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Add Modal -->
                    <!-- Edit Modal -->
                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
                        <form>
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="editModalLabel">Edit Team Member Data</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Name:</label>
                                        <input class="form-control mb-3" v-model="updateName" type="text" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Designation:</label>
                                        <input class="form-control mb-3" v-model="updateDesignation" type="text" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Member Since:</label>
                                        <input class="form-control mb-3" v-model="updateMemberSince" type="text" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Photo:</label>
                                        <input class="form-control" type="file" @change="onUpdatePhotoChange" ref="updatePhotoFile">
                                    </div>
                                    <div class="modal-body">
                                        <img class="img-thumbnail" :src="$imageURL + 'team/' + memberPhoto" height="50px">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Short Title:</label>
                                        <textarea class="form-control" v-model="updateShortDetail" rows="3"></textarea>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Details:</label>
                                        <textarea class="form-control" v-model="updateDetail" rows="3"></textarea>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Phone:</label>
                                        <input class="form-control mb-3" type="text" v-model="updatePhone" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Email:</label>
                                        <input class="form-control mb-3" type="email" v-model="updateEmail" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Facebook Link:</label>
                                        <input class="form-control mb-3" type="text" v-model="updateFacebookLink" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Twitter Link:</label>
                                        <input class="form-control mb-3" type="text" v-model="updateTwitterLink" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Linkedin Link:</label>
                                        <input class="form-control mb-3" type="text" v-model="updateLinkedinLink" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Instagram Link:</label>
                                        <input class="form-control mb-3" type="text" v-model="updateInstagramLink" aria-label="default input example">
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Youtube Link:</label>
                                        <input class="form-control mb-3" type="text" v-model="updateYoutubeLink" aria-label="default input example">
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" v-on:click="updateTeamMember(singleContent.id)" class="btn btn-primary" data-bs-dismiss="modal">Update</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Edit Modal -->
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Team Member List Table</h6><hr/>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Name</th>
                                    <th>Designation</th>
                                    <th>Photo</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in memberList" :key="index">
                                    <td> {{ index + 1 }} </td>
                                    <td> {{ row['name'] }} </td>
                                    <td> {{ row['designation'] }} </td>
                                    <td> <img :src="$imageURL + 'team/' + row.photo" height="50"> </td>
                                    <td> {{ row['phone'] }} </td>
                                    <td> {{ row['email'] }} </td>
                                    <td> {{ $filters.formatDate(row['createdAt']) }} </td>
                                    <td> 
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" v-on:click="editMember(row['id'])" data-bs-toggle="modal" data-bs-target="#editModal" class="edit-button"><i class='bx bxs-edit'></i></a>
                                            <a href="javascript:void(0);" v-on:click="deleteMember(row['id'])" class="ms-3 delete-button"><i class='bx bxs-trash'></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Name</th>
                                    <th>Designation</th>
                                    <th>Photo</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
    background-color: #17a00e;
}
.delete-button {
    background-color: #f41127;
}
</style>

<script src="../js/team-member-data.js"></script>