<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home"
                  ><i class="bx bx-home-alt"></i
                ></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Product Sell Request
              </li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <i class="bx bxs-plus-square"></i>Add New Sell Request
            </button>
          </div>

          <!-- Add Product gallery Modal -->

          <div
            class="modal fade"
            id="addModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="addProductSellRequest">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Product Sell Request</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Name:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="name"
                      placeholder="Product Name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Price:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="price"
                      placeholder="Product Price"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Description:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="description"
                      placeholder="Product Description"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Video Link:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="videoLink"
                      placeholder="Product Video Link"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Location:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="location"
                      placeholder="Product Location"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Product Category:</label>
                    <select v-model="selectedCategory" class="form-select mb-3">
                      <option style="display: none" selected value="">
                        select category
                      </option>
                      <option
                        v-for="(category, index) in productCategoryList"
                        :key="index"
                        :value="{ id: category.id, name: category.name }"
                      >
                        {{ category.name }}
                      </option>
                    </select>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Request Status:</label>
                    <select
                      v-model="requestStatus"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option style="display: none" selected value="">
                        select request status
                      </option>
                      <option value="pending">Pending</option>
                      <option value="accepted">Accepted</option>
                    </select>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- Add Role Modal -->
          <!-- Edit Role Modal -->

          <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="editModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="updateProductSellRequest(singleContent.id)">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Product</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Name:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleContent.name"
                      placeholder="Product category name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Price:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleContent.price"
                      placeholder="Product category name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Description:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleContent.description"
                      placeholder="Product category name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Video Link:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleContent.videoLink"
                      placeholder="Product category name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Location:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleContent.location"
                      placeholder="Product category name"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Product Category:</label>
                    <select v-model="selectedCategory" class="form-select mb-3">
                      <option style="display: none" selected value="">
                        {{ singleContent.categoryName }}
                      </option>
                      <option
                        v-for="(category, index) in productCategoryList"
                        :key="index"
                        :value="{ id: category.id, name: category.name }"
                      >
                        {{ category.name }}
                      </option>
                    </select>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Request Status:</label>
                    <select
                      v-model="requestStatus"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option style="display: none" selected value="">
                        {{singleContent.requestStatus}}
                      </option>
                      <option value="pending">Pending</option>
                      <option value="accepted">Accepted</option>
                    </select>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- Edit Role Modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Product Sell Request Table</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example2" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Category</th>
                  <th>Description</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in productSellRequestList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ row.name }}</td>
                  <td>{{ row.price }}</td>
                  <td>{{ row.categoryName }}</td>
                  <td>{{ row.description }}</td>
                  <td>{{ row.createdAt }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        v-on:click="editProductSellRequest(row.id)"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                        class="ms-1 edit-button text-white"
                        ><i class="bx bxs-edit"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteProductGallery(row.id)"
                        class="ms-1 delete-button text-white"
                        ><i class="bx bxs-trash"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Category</th>
                  <th>Description</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>

<script src="../js/product-sell-request.js"></script>