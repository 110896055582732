import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "TeamContactData",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [] 
        }
    },
    async created() {
        document.title = "Gun Buy Sell - Team Contact Data";
        await this.getTeamContactData();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        
    },
    methods: {
        getTeamContactData: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.team.getContactDataURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        deleteContent: function(id) {
            var answer = window.confirm("Are you sure to delete this content?");
            if (answer) {
                this.isLoading = true;
                let config = {
                    method: "DELETE",
                    url: this.$serverURL + this.$api.team.deleteContactDataURL + id,
                    headers: authHeader()
                };
                this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully deleted data!"
                        });
                    }
                    this.getTeamContactData();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
        },
    }
}