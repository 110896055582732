<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">About Us</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">About Assist Block CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body p-4">
                    <h5 class="card-title">About Assist Block CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Assist Block Title</label>
                                        <input type="text" v-model="assistBlockTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Assist Block Sub Title</label>
                                        <input type="text" v-model="assistBlockSubTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Assist Block One Title</label>
                                        <input type="text" v-model="assistBlockOneTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Assist Block One Details</label>
                                        <textarea class="form-control" v-model="assistBlockOneDetails" rows="3"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="form-label">Assist Block One Image</label>
                                                <input class="form-control" type="file" @change="onAssistBlockOneImage" ref="blockOneImage">
                                            </div>
                                            <div class="col-md-6">
                                                <img :src="$imageURL + 'about/' + blockOneImage" height="80" width="80">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Assist Block Two Title</label>
                                        <input type="text" v-model="assistBlockTwoTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Assist Block Two Details</label>
                                        <textarea class="form-control" v-model="assistBlockTwoDetails" rows="3"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="form-label">Assist Block Two Image</label>
                                                <input class="form-control" type="file" @change="onAssistBlockTwoImage" ref="blockTwoImage">
                                            </div>
                                            <div class="col-md-6">
                                                <img :src="$imageURL + 'about/' + blockTwoImage" height="80" width="80">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Assist Block Three Title</label>
                                        <input type="text" v-model="assistBlockThreeTitle" class="form-control">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Assist Block Three Details</label>
                                        <textarea class="form-control" v-model="assistBlockThreeDetails" rows="3"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="form-label">Assist Block Three Image</label>
                                                <input class="form-control" type="file" @change="onAssistBlockThreeImage" ref="blockThreeImage">
                                            </div>
                                            <div class="col-md-6">
                                                <img :src="$imageURL + 'about/' + blockThreeImage" height="80" width="80">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/about-assist-block-cms.js"></script>