import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "FAQContent",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            contentList: [],
            categoryList: [],
            questionTitle: null,
            questionDetails: null,
            selectedCategory: null,
            singleContent: [],
            updateQuestionTitle: null,
            updateQuestionDetails: null,
            updateSelectedCategoryID: null,
            updateSelectedCategoryName: "",
            singleSelectedCategory: "",
            contentID: null  
        }
    },
    async created() {
        document.title = "Gun Buy Sell - FAQ Content";
        await this.getFaqCategory();
        await this.getContentList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getFaqCategory: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.faq.getCategoryListURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.categoryList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getContentList: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.faq.getContentListURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.contentList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        addContent: function() {
            this.isLoading = true;
            let data = {
                faqCategoryId: this.selectedCategory.id,
                faqCategoryName: this.selectedCategory.name,
                questionTitle: this.questionTitle,
                questionDetail: this.questionDetails
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.faq.addContentURL,
                data: data,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully stored data!"
                    });
                    this.questionTitle = "";
                    this.questionDetails = "";
                    this.selectCategoryId = "";
                }
                this.getContentList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        deleteContent: function(id) {
            var answer = window.confirm("Are you sure to delete this content?");
            if (answer) {
                this.isLoading = true;
                let config = {
                    method: "DELETE",
                    url: this.$serverURL + this.$api.faq.deleteContentURL + id,
                    headers: authHeader()
                };
                this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully deleted data!"
                        });
                    }
                    this.getContentList();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
        },
        editContent: function(id) {
            this.isLoading = true;
            this.contentID = id;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.faq.getSingleContentURL + id,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.singleContent = response.data;
                    this.updateQuestionTitle = this.singleContent.questionTitle;
                    this.updateQuestionDetails = this.singleContent.questionDetail;
                    this.updateSelectedCategoryName = this.singleContent.faqCategoryName;
                    this.updateSelectedCategoryID = this.singleContent.faqCategoryId;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        updateContent: function(id) {
            this.isLoading = true;
            let data = {
                questionTitle: this.updateQuestionTitle,
                questionDetail: this.updateQuestionDetails,
                faqCategoryName: this.singleSelectedCategory.name,
                faqCategoryId: this.singleSelectedCategory.id
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.faq.updateContentURL + id,
                data: data,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                this.getContentList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}