import Loading from "vue-loading-overlay";

export default {
    name: "PendingListing",
    components: {
        Loading,
    },
    props:["productData"],
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            viewProduct: {},
            imageUrl : [],
            attachmentUrl : [],
            featuredPricingTxt : 'Not required'
        }
    },
    watch: {
        productData: async function () {
            this.viewProduct = this.$props.productData;
            console.log(this.viewProduct);
            this.imageUrl = [];
            this.attachmentUrl = [];
            let imageUrls = [];
            let attachmentUrls = [];
            if(Object.keys(this.viewProduct.photos).length > 0){
                let photo = this.viewProduct.photos;
                photo.map(function(value, key) {
                    if(value.itemType=="Gallery"){
                        imageUrls.push(value.image);
                    }
                    if(value.itemType=="Attachment"){
                        attachmentUrls.push(value.image);
                    }
                });
                this.imageUrl.push(imageUrls);
                this.attachmentUrl.push(attachmentUrls);
            }            
            this.featuredPricingTxt = 'Not required'
            if(Object.keys(this.viewProduct.featuredPricing).length > 0){
                this.featuredPricingTxt = this.viewProduct.featuredPricing.featuredName;
            }
          }
          
    }
}