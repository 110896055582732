import Loading from "vue-loading-overlay";
import $ from 'jquery';
export default {
    name: "AddCategory",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            name: null,
            isSlider: false,
            isFeatured: false,
            featuredStatus: 0,
            sliderStatus: 0,
            featuredImage: null,
            categoryIcon: null
        }
    },
    created() {
        document.title = "Gun Buy Sell - Add Category";
        
    },
    mounted() {
        $("#checkCategoryIcon").hide();
        $("#checkFeaturedImage").hide();
    },
    methods: {
        onIconChange(event) {
            this.categoryIcon = event.target.files[0];
        },
        onImageChange(event) {
            this.featuredImage = event.target.files[0];
        },
        save: function() {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            this.featuredStatus = this.isFeatured ? 1 : 0;
            this.sliderStatus = this.isSlider ? 1 : 0;
            const formData = new FormData();
            formData.append("name", this.name);
            formData.append("isSlider", this.sliderStatus);
            formData.append("isFeatured", this.featuredStatus);
            formData.append("categoryIcon", this.categoryIcon);
            formData.append("featuredImage", this.featuredImage);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.product.addCategoryURL,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully created data!"
                    });
                    this.name = null;
                    this.isSlider = false;
                    this.isFeatured = false;
                    this.featuredStatus = 0;
                    this.sliderStatus = 0;
                    this.featuredImage = null;
                    this.categoryIcon = null;
                    this.$refs.categoryIconFile.value = null;
                    this.$refs.featuredImageFile.value = null;
                }
                this.isLoading = false;
                this.$router.push({ name: 'ProductCategory' });
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        checkIsSlider: function(e) {
            this.$nextTick(() => {
                if(this.isSlider == true) {
                    $("#checkCategoryIcon").show();
                } else {
                    $("#checkCategoryIcon").hide();
                }
            })
        },
        checkIsFeatured: function(e) {
            this.$nextTick(() => {
                if(this.isFeatured == true) {
                    $("#checkFeaturedImage").show();
                } else {
                    $("#checkFeaturedImage").hide();
                }
            })
        }
    }
}