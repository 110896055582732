import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "ContactData",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            contactDataList: []
        }
    },
    async created() {
        document.title = "Gun Buy Sell - Contact Data";
        await this.getContactData();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        
    },
    methods: {
        getContactData: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.contact.getContactDataURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.contactDataList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        // addRole: function() {
        //     this.isLoading = true;
        //     let data = {
        //         name: this.roleName
        //     };
        //     let config = {
        //         method: "POST",
        //         url: this.$serverURL + this.$api.role.addRoleURL,
        //         data: data,
        //         headers: authHeader()
        //     };
        //     this.$axios(config).then((response) => {
        //         if(response.status == 200) {
        //             this.$swal.fire({
        //                 icon: "success",
        //                 text: "Successfully stored data!"
        //             });
        //             this.roleName = "";
        //         }
        //         this.isLoading = false;
        //         this.$router.go();
        //     }).catch(error => {
        //         console.log(error);
        //         this.isLoading = false;
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Something went wrong. Please try again!"
        //         });
        //     });
        // },
        // deleteRole: function(id) {
        //     var answer = window.confirm("Are you sure to delete this role?");
        //     if (answer) {
        //         this.isLoading = true;
        //         let config = {
        //             method: "DELETE",
        //             url: this.$serverURL + this.$api.role.deleteRoleURL + id,
        //             headers: authHeader()
        //         };
        //         this.$axios(config).then((response) => {
        //             if(response.status == "success") {
        //                 this.$swal.fire({
        //                     icon: "success",
        //                     text: "Successfully deleted data!"
        //                 });
        //             }
        //             this.isLoading = false;
        //         }).catch(error => {
        //             console.log(error);
        //             this.isLoading = false;
        //             this.$swal.fire({
        //                 icon: "error",
        //                 text: "Something went wrong. Please try again!"
        //             });
        //             this.$toast.success("Something went wrong. Please try again!");
        //         });
        //     }
        // },
        // setId: function(index) {
        //     this.singleRole = this.roleList[index];
        // },
        // updateRole: function(id) {
        //     this.isLoading = true;
        //     let data = {
        //         name: this.singleRole.name
        //     };
        //     let config = {
        //         method: "PATCH",
        //         url: this.$serverURL + this.$api.role.updateRoleURL + id,
        //         data: data,
        //         headers: authHeader()
        //     };
        //     this.$axios(config).then((response) => {
        //         if(response.status == "success") {
        //             this.$swal.fire({
        //                 icon: "success",
        //                 text: "Successfully updated data!"
        //             });
        //         }
        //         this.isLoading = false;
        //         this.$router.go();
        //     }).catch(error => {
        //         console.log(error);
        //         this.isLoading = false;
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Something went wrong. Please try again!"
        //         });
        //     });
        // }
    }
}