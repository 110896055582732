import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "ProductCategory",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            categoryList: [],
            singleCategory: [],
            isSlider: null,
            isFeatured: null,
            categoryIconUrl: null,
            featuredImageUrl: null
        }
    },
    
    async created() {
        document.title = "Gun Buy Sell - Product Category";
        await this.getCategoryList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        
    },
    methods: {
        getCategoryList: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.getCategoryListURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.categoryList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        deleteCategory: function(id) {
            var answer = window.confirm("Are you sure to delete this category?");
            if (answer) {
                this.isLoading = true;
                let config = {
                    method: "DELETE",
                    url: this.$serverURL + this.$api.product.deleteCategoryURL + id,
                    headers: authHeader()
                };
                this.$axios(config).then((response) => {
                    if(response.data.deleted == true) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully deleted data!"
                        });
                    }
                    this.isLoading = false;
                    this.getCategoryList();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
        },
        onIconChange(event) {
            this.singleCategory.categoryIcon = event.target.files[0];
        },
        onImageChange(event) {
            this.singleCategory.featuredImage = event.target.files[0];
        },
        setId: function(index) {
            this.singleCategory = this.categoryList[index];
            this.isSlider = this.singleCategory.isSlider == 1 ? true : false; 
            this.isFeatured = this.singleCategory.isFeatured == 1 ? true : false;
            this.categoryIconUrl = this.singleCategory.categoryIcon;
            this.featuredImageUrl = this.singleCategory.featuredImage; 
        },
        updateCategory: function(id) {
            this.isLoading = true;
            this.singleCategory.isSlider = this.isSlider ? 1 : 0;
            this.singleCategory.isFeatured = this.isFeatured ? 1 : 0;
            const formData = new FormData();
            formData.append("name", this.singleCategory.name);
            formData.append("isSlider", this.singleCategory.isSlider);
            formData.append("isFeatured", this.singleCategory.isFeatured);
            formData.append("categoryIcon", this.singleCategory.categoryIcon);
            formData.append("featuredImage", this.singleCategory.featuredImage);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.product.updateCategoryURL + id,
                data: formData,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                    this.isSlider = null;
                    this.isFeatured = null;
                }
                this.isLoading = false;
                this.getCategoryList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}