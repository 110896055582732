<template>
    <div class="page-wrapper">
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Blog Comment</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Blog Comment Data</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Blog Comment Table</h6><hr/>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Post Id</th>
                                    <th>Comment</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Website</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in blogCommentList" :key="index">
                                    <td> {{ index+1 }} </td>
                                    <td> {{ row.blogPost.title }} </td>
                                    <td> {{ row.comment }} </td>
                                    <td> {{ row.name }} </td>
                                    <td> {{ row.email }} </td>
                                    <td> {{ row.website }} </td>
                                    <td> 
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" v-on:click="deleteComment(row.id)" class="ms-3 delete-button"><i class='bx bxs-trash'></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Sl</th>
                                    <th>Post Id</th>
                                    <th>Comment</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Website</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
    background-color: #17a00e;
  }

  .delete-button {
    background-color: #f41127;
  }
</style>

<script src="../js/blog-comment.js"></script>