import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";

export default {
    name: "HomeTestimonial",
    components: [Loading],
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            name: "",
            designation: "",
            rating: "",
            feedbackDetail: "",
            socialLink: "",
            selectedPhoto: null,
            selectedSocialIcon: null,
            singleContent: []
        }
    },
    async created() {
        document.title = "Gun Buy Sell - Home Testimonial";
        await this.getHomeTestimonialList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getHomeTestimonialList: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.home.getHomeTestimonialURL,
                headers: authHeader()
            };
            await this.axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data;
                }
            }).catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onPhotoChange(event) {
            this.selectedPhoto = event.target.files[0];
        },
        onSocialIconChange(event) {
            this.selectedSocialIcon = event.target.files[0];
        },
        addContent: function() {
            this.isLoading = true;
            const formData = new FormData();
            formData.append("name", this.name);
            formData.append("designation", this.designation);
            formData.append("rating", this.rating);
            formData.append("feedbackDetail", this.feedbackDetail);
            formData.append("socialLink", this.socialLink);
            formData.append("photo", this.selectedPhoto);
            formData.append("socialIcon", this.selectedSocialIcon);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.home.addHomeTestimonialURL,
                data: formData,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if (response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully stored data!"
                    });
                    this.isLoading = false;
                    this.name = "";
                    this.designation = "";
                    this.rating = "";
                    this.feedbackDetail = "";
                    this.socialLink = "";
                    this.selectedPhoto = null;
                    this.selectedSocialIcon = null;
                    this.$refs.photoFile.value = null;
                    this.$refs.socialIconFile.value = null;
                    this.getHomeTestimonialList();
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        editContent: function(id) {
            this.isLoading = true;
            this.contentID = id;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.home.getSingleHomeTestimonial + id,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.singleContent = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        updateContent: async function(id) {
            this.isLoading = true;
            const formData = new FormData();
            formData.append("name", this.singleContent.name);
            formData.append("designation", this.singleContent.designation);
            formData.append("rating", this.singleContent.rating);
            formData.append("feedbackDetail", this.singleContent.feedbackDetail);
            formData.append("socialLink", this.singleContent.socialLink);
            formData.append("photo", this.selectedPhoto);
            formData.append("socialIcon", this.selectedSocialIcon);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.home.updateHomeTestimonialURL + id,
                data: formData,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.isLoading = false;
                    this.selectedPhoto = null;
                    this.selectedSocialIcon = null;
                    this.getHomeTestimonialList();
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        deleteContent: function(id) {
            var answer = window.confirm("Are you sure to delete this data?");
            if (answer) {
                this.isLoading = true;
                let config = {
                    method: "DELETE",
                    url: this.$serverURL + this.$api.home.deleteHomeTestimonialURL + id,
                    headers: authHeader()
                };
                this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully deleted data!"
                        });
                    }
                    this.isLoading = false;
                    this.getHomeTestimonialList();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
        }
    }
}