import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "SiteSetting",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            siteName: null,
            callSupport: null,
            supportEmail: null,
            companyAddress: null,
            copyrightText: null,
            headerLogo: null,
            footerLogo: null,
            headerLogoFile: null,
            footerLogoFile: null
        }
    },
    created() {
        document.title = "Gun Buy Sell - Site Setting";
        this.getSiteSettingData();
    },
    methods: {
        getSiteSettingData: function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.site.getSiteSettingURL,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data[0];
                    this.siteName = this.dataList.siteName;
                    this.callSupport = this.dataList.callSupport;
                    this.supportEmail = this.dataList.supportEmail;
                    this.companyAddress = this.dataList.companyAddress;
                    this.copyrightText = this.dataList.copyrightText;
                    this.headerLogoFile = this.dataList.headerLogo;
                    this.footerLogoFile = this.dataList.footerLogo;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        headerFileChange(event) {
            this.headerLogo = event.target.files[0];
        },
        footerFileChange(event) {
            this.footerLogo = event.target.files[0];
        },
        update: function() {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("siteName", this.siteName);
            formData.append("callSupport", this.callSupport);
            formData.append("supportEmail", this.supportEmail);
            formData.append("companyAddress", this.companyAddress);
            formData.append("copyrightText", this.copyrightText);
            formData.append("headerLogo", this.headerLogo);
            formData.append("footerLogo", this.footerLogo);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.site.updateSiteSettingURL,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                this.$refs.formHeaderFile.value = null;
                this.$refs.formFooterFile.value = null;
                this.isLoading = false;
                this.getSiteSettingData();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}