import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "TeamMemberData",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            memberList: [],
            name: null,
            designation: null,
            memberSince: null,
            photo: null,
            shortDetail: null,
            detail: null,
            phone: null,
            email: null,
            facebookLink: null,
            twitterLink: null,
            linkedinLink: null,
            instagramLink: null,
            youtubeLink: null,
            memberStatus: null,
            updateName: null,
            updateDesignation: null,
            updateMemberSince: null,
            updatePhoto: null,
            updateShortDetail: null,
            updateDetail: null,
            updatePhone: null,
            updateEmail: null,
            updateFacebookLink: null,
            updateTwitterLink: null,
            updateLinkedinLink: null,
            updateInstagramLink: null,
            updateYoutubeLink: null,
            memberPhoto: null
        }
    },
    async created() {
        document.title = "Gun Buy Sell - Team Member Data";
        await this.getTeamMemberList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getTeamMemberList: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.team.getTeamMemberURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.memberList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onPhotoChange(event) {
            this.photo = event.target.files[0];
        },
        onUpdatePhotoChange(event) {
            this.updatePhoto = event.target.files[0];
        },
        addTeamMember: function() {
            this.isLoading = true;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("name", this.name);
            formData.append("designation", this.designation);
            formData.append("memberSince", this.memberSince);
            formData.append("shortDetail", this.shortDetail);
            formData.append("detail", this.detail);
            formData.append("phone", this.phone);
            formData.append("email", this.email);
            formData.append("facebookLink", this.facebookLink);
            formData.append("twitterLink", this.twitterLink);
            formData.append("linkedinLink", this.linkedinLink);
            formData.append("instagramLink", this.instagramLink);
            formData.append("youtubeLink", this.youtubeLink);
            formData.append("memberStatus", this.memberStatus);
            formData.append("photo", this.photo);
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.team.addTeamMemberURL,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully stored data!"
                    });
                }
                this.isLoading = false;
                this.getTeamMemberList();
                this.name = '';
                this.designation = '';
                this.memberSince = '';
                this.shortDetail = '';
                this.detail = '';
                this.phone = '';
                this.email = '';
                this.facebookLink = '';
                this.twitterLink = '';
                this.linkedinLink = '';
                this.instagramLink = '';
                this.youtubeLink = '';
                this.memberStatus = '';
                this.$refs.photoFile.value = null;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        deleteMember: function(id) {
            var answer = window.confirm("Are you sure to delete this data?");
            if (answer) {
                this.isLoading = true;
                let config = {
                    method: "DELETE",
                    url: this.$serverURL + this.$api.team.deleteTeamMemberURL + id,
                    headers: authHeader()
                };
                this.$axios(config).then((response) => {
                    if(response.data.deleted == true) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully deleted data!"
                        });
                    }
                    this.isLoading = false;
                    this.getTeamMemberList();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
        },
        editMember: function(id) {
            this.isLoading = true;
            this.contentID = id;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.team.editTeamMemberURL + id,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.singleContent = response.data;
                    console.log(this.singleContent);
                    this.updateName = this.singleContent.name;
                    this.updateDesignation = this.singleContent.designation;
                    this.updateMemberSince = this.singleContent.updateMemberSince;
                    this.updateShortDetail = this.singleContent.shortDetail;
                    this.updateDetail = this.singleContent.detail;
                    this.updatePhone = this.singleContent.phone;
                    this.updateEmail = this.singleContent.email;
                    this.updateFacebookLink = this.singleContent.facebookLink;
                    this.updateTwitterLink = this.singleContent.twitterLink;
                    this.updateLinkedinLink = this.singleContent.linkedinLink;
                    this.updateInstagramLink = this.singleContent.instagramLink;
                    this.updateYoutubeLink = this.singleContent.youtubeLink;
                    this.memberPhoto = this.singleContent.photo;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        updateTeamMember: function(id) {
            this.isLoading = true;
            this.contentID = id;
            let token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("name", this.updateName);
            formData.append("designation", this.updateDesignation);
            formData.append("updateMemberSince", this.updateMemberSince);
            formData.append("shortDetail", this.updateShortDetail);
            formData.append("detail", this.updateDetail);
            formData.append("phone", this.updatePhone);
            formData.append("email", this.updateEmail);
            formData.append("facebookLink", this.updateFacebookLink);
            formData.append("twitterLink", this.updateTwitterLink);
            formData.append("linkedinLink", this.updateLinkedinLink);
            formData.append("instagramLink", this.updateInstagramLink);
            formData.append("youtubeLink", this.updateYoutubeLink);
            formData.append("photo", this.updatePhoto);
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.team.updateTeamMemberURL + id,
                data: formData,
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                this.isLoading = false;
                this.getTeamMemberList();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}