<template>
	<div class="page-wrapper">
		<loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
		<div class="page-content">
			<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
				<div class="breadcrumb-title pe-3">Blog Post</div>
				<div class="ps-3">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb mb-0 p-0">
							<li class="breadcrumb-item">
								<router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								Blog Post
							</li>
						</ol>
					</nav>
				</div>
				<div class="ms-auto">
					<div class="btn-group">
						<button type="button" class="btn btn-primary" @click="$router.push('/blog-post')">
							<i class="bx bx-coin-stack"></i> Blog Post List
						</button>
					</div>
					<!-- Add Content Modal -->
					<!-- Add Content Modal -->
					<!-- Edit Content Modal -->

					<!-- Edit Content Modal -->
				</div>
			</div>
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-xl-12 mx-auto">
							<h6 class="mt-2 mb-2 text-uppercase">Edit Blog</h6>
							
							<!-- <div class="card">
							<div class="card-body"> -->

							<!-- SmartWizard html -->
							<div id="smartwizard">
								<ul class="nav">
									<li class="nav-item">
										<a class="nav-link" href="#step-1"> <strong>Step 1</strong>
											<br>Blog Infromation</a>
									</li>
									<li class="nav-item">
										<a class="nav-link" href="#step-2"> <strong>Step 2</strong>
											<br>Blog Image &amp; Tags</a>
									</li>
									<li class="nav-item">
										<a class="nav-link" href="#step-3"> <strong>Step 3</strong>
											<br>Blog Uploader Information</a>
									</li>
								</ul>
								<div class="tab-content" style="height:auto !important;">
									<div id="step-1" class="tab-pane" role="tabpanel" aria-labelledby="step-1">
										<!-- Step One Start -->

										<div class="card border-top border-0 border-4 border-primary">
											<div class="card-body p-5">
												<div class="card-title d-flex align-items-center">
													<div><i class="bx bxs-user me-1 font-22 text-primary"></i>
													</div>
													<h5 class="mb-0 text-primary">Enter Blog Information </h5>
												</div>
												<hr>
												<form class="row g-3">
													<div class="col-md-12">
														<label for="inputFirstName" class="form-label">Post
															Title</label>
														<input type="text" v-model="singleContent.title" class="form-control" id="inputFirstName" placeholder="Post title">
													</div>
													<div class="col-12">
														<label for="inputAddress" class="form-label">Short
															Details</label>
														<Editor api-key="44lamukdf4uvndgqk5nzsrlp6sx2r5yy35eokwtslcnqpmwl"  v-model="singleContent.shortDetail" />
													</div>
													<div class="col-12">
														<label for="inputAddress" class="form-label">Long
															Details</label>
														<Editor api-key="44lamukdf4uvndgqk5nzsrlp6sx2r5yy35eokwtslcnqpmwl"  v-model="singleContent.longDetail" />
													</div>
													<div class="col-md-6">
														<label class="form-label">Category:</label>
														<select v-model="singleContent.category" class="form-select mb-3" >
														<option
															v-for="(row, index) in blogCategory"
															:key="index"
															:value="{ id: row.id, name: row.name }"
														>
															{{ row.name }}
														</option>
														</select>
													</div>
													<div class="col-md-6">
														<div class="form-check form-switch">
															<label class="form-check-label custom-margin-label">Is Popular?</label>
															<input class="form-check-input custom-margin" v-model="singleContent.isPopular" type="checkbox">
														</div>
													</div>
												</form>
											</div>
										</div>

										<!-- <button @click="TestEditorData()">Test Data From Editor in console</button> -->
										<!-- Step One End -->

									</div>
									<div id="step-2" class="tab-pane" role="tabpanel" aria-labelledby="step-2">
										<div class="card border-top border-0 border-4 border-primary">
											<div class="card-body p-5">
												<div class="card-title d-flex align-items-center">
													<div><i class="bx bxs-user me-1 font-22 text-primary"></i>
													</div>
													<h5 class="mb-0 text-primary">Enter Blog Tags </h5>
												</div>
												<hr>
												<form class="row g-3">
													<div class="col-md-4">
														<label class="form-label">Upload Post Image:</label>
														<input
														class="form-control"
														type="file"
														id="formFile"
														accept="image/gif, image/jpeg, image/png, image/svg"
														@change="onPostImageChange"
														/>
													</div>
													<div class="col-md-2">
														<div class="mb-3">
															<img :src="$imageURL + 'blog/' + singleContent.photo" height="100" width="100">
														</div>
													</div>
													
													<div class="col-md-6 mb-3">
														<label class="form-label">Blog Tag:</label>
														<Multiselect
														v-model="selectedBlogTag"
														:options="options"
														track-by="id"
														mode="tags"
														:close-on-select="false"
														:searchable="true"
														:create-option="true"
														/>
													</div>
													<div class="col-md-6 mb-3">
														<label class="form-label">Related Posts:</label>
														<Multiselect
															v-model="selectedRelatedPosts"
															:options="relatedOptions"
															mode="tags"
															openDirection="top" 
															:close-on-select="false"
															:searchable="true"
															:create-option="true"
														/>
													</div>
												</form>
											</div>
										</div>
									</div>
									<div id="step-3" class="tab-pane" role="tabpanel" aria-labelledby="step-3">
										<div class="card border-top border-0 border-4 border-primary">
											<div class="card-body p-5">
												<div class="card-title d-flex align-items-center">
													<div><i class="bx bxs-user me-1 font-22 text-primary"></i>
													</div>
													<h5 class="mb-0 text-primary">Enter Uploader Info </h5>
												</div>
												<hr>
												<form class="row g-3">
													<div class="col-md-4">
														<label class="form-label">Uploader Image:</label>
														<input
														class="form-control"
														type="file"
														id="formFile"
														accept="image/gif, image/jpeg, image/png, image/svg"
														@change="onUploaderImageChange"
														/>
													</div>
													<div class="col-md-2">
														<div class="mb-3">
															<img :src="$imageURL + 'blog/' + singleContent.UploaderPhoto" height="100" width="100">
														</div>
													</div>
													
													<div class="col-md-6">
														<label class="form-label">Posted By:</label>
														<input
														class="form-control mb-3"
														type="text"
														v-model="updatePostedBy"
														placeholder=""
														aria-label="default input example"
														disabled
														/>
													</div>
													
												</form>
												<button
													@click="updatePost()"
													type="button"
													class="btn btn-primary"
													>
													Save
													</button>
											</div>
										</div>
									</div>

								</div>
							</div>
							<!-- </div>
						</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.edit-button {
	background-color: #17a00e;
}

.delete-button {
	background-color: #f41127;
}
</style>
<script src="../js/blog-edit-post.js"></script>
<style>
	/*@import '../../../../public/core/assets/plugins/simplebar/css/simplebar.css'; */
	@import '../../../../public/core/assets/plugins/perfect-scrollbar/css/perfect-scrollbar.css';
	@import '../../../../public/core/assets/plugins/metismenu/css/metisMenu.min.css';
	@import '../../../../public/core/assets/plugins/smart-wizard/css/smart_wizard_all.min.css';
	/* @import '../../../../public/core/assets/plugins/select2/css/select2.min.css';
	@import '../../../../public/core/assets/plugins/select2/css/select2-bootstrap4.css'; */
</style>