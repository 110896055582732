<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <!-- Edit Product Modal -->

      <div
        class="modal fade bd-example-modal-lg"
        id="viewModal"
        tabindex="-1"
        aria-labelledby="viewModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addModalLabel">Product Detail</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="inputFirstName" class="form-label"
                    >Listing Name</label
                  >
                  <input
                    type="text"
                    readonly
                    v-model="viewProduct.name"
                    class="form-control"
                    id="inputFirstName"
                  />
                </div>
                <div class="col-md-6">
                  <label for="inputLastName" class="form-label">Category</label>
                  <input
                    type="text"
                    readonly
                    v-if="viewProduct.category"
                    v-model="viewProduct.category.name"
                    class="form-control"
                    id="inputLastName"
                  />
                </div>
                <div class="col-md-6">
                  <label for="inputLastName" class="form-label">Price</label>
                  <input
                    type="text"
                    readonly
                    
                    v-model="viewProduct.price"
                    class="form-control"
                    id="inputLastName"
                  />
                </div>
                <div class="col-md-6">
                  <label for="inputLastName" class="form-label"
                    >Featured Pricing</label
                  >
                 
                  <input
                    type="text"
                    readonly
                    v-if="viewProduct.featuredPricing"
                    v-model="featuredPricingTxt"
                    class="form-control"
                    id="inputLastName"
                  />
                </div>
                <div class="col-12">
                  <label for="inputAddress" class="form-label"
                    >Description</label
                  >
                  <div v-html="viewProduct.description"></div>
                </div>
                <div class="col-md-12">
                  <label for="inputCity" class="form-label">Video Link</label>
                  <input
                    type="text"
                    readonly
                    v-model="viewProduct.videoLink"
                    class="form-control"
                    id="inputCity"
                  />
                </div>
                <div class="col-md-12">
              
                  <label for="inputState" class="form-label">Gallery</label>            
                    <div class="row" v-if="imageUrl !=''">
                      <div class="col-sm-2 product-grid"
                      v-for="(row, index) in imageUrl[0]" :key="index">
                        <div class="col">
                        <div class="card">
                          <img :src="$imageURL + 'product/' + row" class="card-img-top" alt="..." width="70" height="70">
                        </div>
                      </div>
                      </div>
                      
                    </div>
                  
                </div>
                <div class="col-md-12">
                  <label for="inputState" class="form-label">Attachment</label>            
                    <div class="row" v-if="attachmentUrl !=''">
                      <div class="col-sm-2 product-grid"
                      v-for="(row, index) in attachmentUrl[0]" :key="index">
                        <div class="col">
                          <!-- <img :src="$imageURL + 'product/' + row" class="card-img-top" alt="..."> -->
                          <a :href="$imageURL + 'product/' + row" target="_blank" class="btn btn-primary btn-sm radius-30 px-4">Link {{ index +1 }}</a>
            
                      </div>
                      </div>
                      
                    </div>
                  
                </div>

                <div class="col-md-6">
                  <label for="inputZip" class="form-label">Full Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="viewProduct.contact_name"
                    class="form-control"
                    id="inputZip"
                  />
                </div>
                <div class="col-md-6">
                  <label for="inputZip" class="form-label"
                    >Contact Number
                  </label>
                  <input
                    type="text"
                    readonly
                    v-model="viewProduct.contact_number"
                    class="form-control"
                    id="inputZip"
                  />
                </div>
                <div class="col-md-6">
                  <label for="inputZip" class="form-label"
                    >Contact Listing Address
                  </label>
                  <input
                    type="text"
                    readonly
                    v-model="viewProduct.contact_address"
                    class="form-control"
                    id="inputZip"
                  />
                </div>
                <div class="col-md-6">
                  <label for="inputZip" class="form-label">Location </label>
                  <input
                    type="text"
                    readonly
                    v-model="viewProduct.location"
                    class="form-control"
                    id="inputZip"
                  />
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Edit Role Modal -->
    </div>
  </div>
</template>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>

<script src="../js/view-product-listing.js"></script>
