<template>
  <loading :active='isLoading' :is-full-page='fullPage' :loader='loader'/>
  <Home v-if='!isLogin'></Home>
  <router-view></router-view>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Home from './components/home/template/Home.vue';
import {authHeader} from "./auth";

export default {
  name: 'App',
  components: {
    Loading, 
    Home
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: 'bars',
      isLogin: true,
      watchLoginCheck: false
    };
  },
  created() {
    if(this.$router.currentRoute.path == '/login' || this.$router.currentRoute.path == '/signup') {
      if(localStorage.getItem('token')) {
        this.$router.push('/');
      }
      this.isLogin = true;
    }
    this.onInactive(1800000, function() {
      this.$swal.fire ({
        icon: 'error',
        text: 'You have been logged out for 30 minutes of inactivity!'
      });
      localStorage.clear();
      this.$router.push('/login');
    });
  },
  methods: {
    onInactive: function(ms, cb) {
      var wait = setTimeout(cb, ms);
      document.onmousemove = document.mousedown = document.mouseup = document.onkeydown = document.onkeyup = document.focus = function() {
        clearTimeout(wait);
        wait = setTimeout(cb, ms);
      };
    }
  },
  watch: {
    '$route'(newPath) {
      if(newPath.path == '/login' || newPath.path == '/signup') {
        this.isLogin = true;
      }
      else {
        this.isLogin = false;
        if(this.watchLoginCheck == false)
        {
          let config = {
            method: "POST",
            url: this.$serverURL + this.$api.auth.verifyAccount,
            headers: authHeader()
          };
          this.$axios(config).then((response) => {
            if(response.status == 201) {
              if(response.data===false)
              {
                localStorage.clear();
                window.location.href="/login";
              }
              else
              {
                this.watchLoginCheck = true;
              }
            }
            this.isLoading = false;
          }).catch(error => {
              console.log(error);
              this.isLoading = false;
          });
        }  
      }
    }
  }
}
</script>