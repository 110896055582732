import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";
import productDetailsModal from "../template/productDetailsModal"

export default {
    name: "ApprovedListing",
    components: {
        Loading,
        productDetailsModal
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            listingList: [],
            productData:[]
        }
    },
    async created() {
        document.title = "Gun Buy Sell - Approved Listing";
        await this.getlistingList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        
    },
    methods: {
        viewProductInfo: async function(index) {
            this.productData = index;
        },
        getlistingList: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.getApprovedProductsURL + '?status=Approved',
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.listingList = response.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}