<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Blog Post Tag</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home"
                  ><i class="bx bx-home-alt"></i
                ></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Blog Post Tag
              </li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <i class="bx bxs-plus-square"></i>Add New Blog Post Tag
            </button>
          </div>
          <!-- Add Content Modal -->
          <div
            class="modal fade"
            id="addModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form>
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body">
                    <label class="form-label">Blog Post:</label>
                    <select v-model="selectedBlogPost" class="form-select mb-3">
                      <option
                        v-for="(post, index) in blogPosts"
                        :key="index"
                        :value="{ id: post.id }"
                      >
                        {{ post.title }}
                      </option>
                    </select>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Blog Tag:</label>
                    <select v-model="selectedBlogTag" class="form-select mb-3">
                      <option
                        v-for="(tag, index) in blogTags"
                        :key="index"
                        :value="{ id: tag.id, name: tag.name }"
                      >
                        {{ tag.name }}
                      </option>
                    </select>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      v-on:click="addBlogPostTags"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Add Content Modal -->
          <!-- Edit Content Modal -->
          <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="editModalLabel"
            aria-hidden="true"
          >
            <form>
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body">
                    <label class="form-label">Blog Post:</label>
                    <select v-model="updateBlogPost" class="form-select mb-3">
                      <option style="display: none" selected value="">
                        {{ singleBlogPostTag.postTitle }}
                      </option>
                      <option
                        v-for="(post, index) in blogPosts"
                        :key="index"
                        :value="{ id: post.id, postTitle: post.title }"
                      >
                        {{ post.title }}
                      </option>
                    </select>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Blog Tag:</label>
                    <select v-model="updateBlogTag" class="form-select mb-3">
                      <option
                        style="display: none"
                        selected="selected"
                        value=""
                      >
                        {{ singleBlogPostTag.tagName }}
                      </option>
                      <option
                        v-for="(tag, index) in blogTags"
                        :key="index"
                        :value="{ id: tag.id, name: tag.name }"
                      >
                        {{ tag.name }}
                      </option>
                    </select>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      v-on:click="updateBlogPostTag"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Edit Content Modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Blog Post Tag List Table</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table
              id="example"
              class="table table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Post Title</th>
                  <th>Tag Name</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in blogPostTags" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ row.postTitle }}</td>
                  <td>{{ row.tagName }}</td>
                  <td>{{ $filters.formatDate(row['createdAt']) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        v-on:click="editBlogPostTag(row.id)"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                        class="edit-button"
                        ><i class="bx bxs-edit"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteBlogPostTag(row.id)"
                        class="ms-3 delete-button"
                        ><i class="bx bxs-trash"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>Post Title</th>
                  <th>Tag Name</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>
<script src="../js/blog-post-tag.js"></script>