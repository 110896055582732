<template>
	<div class="wrapper">
		<!-- Start Sidebar -->
		<div class="sidebar-wrapper" data-simplebar="true">
			<div class="sidebar-header">
				<div>
					<img src="../../../../public/core/assets/images/logo-img.png" class="logo-icon" alt="logo icon">
				</div>
				<div>
					<h4 class="logo-text">GBS</h4>
				</div>
				<div class="toggle-icon ms-auto"><i class='bx bx-arrow-to-left'></i></div>
			</div>
			<ul class="metismenu" id="menu">
				<li>
                    <a href="javascript:void(0);" @click="$router.push('/dashboard')">
                        <div class="parent-icon"><i class='lni lni-dashboard'></i></div>
                        <div class="menu-title">Dashboard</div>
                    </a>
                </li>
                <!-- <li v-if="role == 1">
                    <a href="javascript:void(0);" @click="$router.push('/role-list')">
                        <div class="parent-icon"><i class='fadeIn animated bx bx-user-x'></i></div>
                        <div class="menu-title">Role</div>
                    </a>
                </li> -->
				<li v-if="role == 1">
                    <a href="javascript:void(0);" @click="$router.push('/user')">
                        <div class="parent-icon"><i class='fadeIn animated bx bx-user'></i></div>
                        <div class="menu-title">User</div>
                    </a>
                </li>
                <li>
                    <a href="#" class="has-arrow">
                        <div class="parent-icon"><i class='lni lni-producthunt'></i></div>
                        <div class="menu-title">Product</div>
                    </a>
                    <ul>
                        <li><a href="javascript:void(0);" @click="$router.push('/product-category')"><i class="bx bx-right-arrow-alt"></i>Category</a></li>
                        <li><a href="javascript:void(0);" @click="$router.push('/product')"><i class="bx bx-right-arrow-alt"></i>Product Listing</a></li>
                        <li><a href="javascript:void(0);" @click="$router.push('/product-gallery')"><i class="bx bx-right-arrow-alt"></i>Product Gallery</a></li>
                        <li><a href="javascript:void(0);" @click="$router.push('/featured-pricing')"><i class="bx bx-right-arrow-alt"></i>Featured Pricing</a></li>
                    </ul>
                </li>
				<li>
                    <a href="#" class="has-arrow">
                        <div class="parent-icon"><i class='lni lni-control-panel'></i></div>
                        <div class="menu-title">Status Wise Listing</div>
                    </a>
                    <ul>
                        <li><a href="javascript:void(0);" @click="$router.push('/pending-listing')"><i class="bx bx-right-arrow-alt"></i>Pending Listing</a></li>
                        <li><a href="javascript:void(0);" @click="$router.push('/approved-listing')"><i class="bx bx-right-arrow-alt"></i>Approved Listing</a></li>
                        <li><a href="javascript:void(0);" @click="$router.push('/rejected-listing')"><i class="bx bx-right-arrow-alt"></i>Rejected Listing</a></li>
                    </ul>
                </li>
                <li>
                    <a href="#" class="has-arrow">
                        <div class="parent-icon"><i class='bx bx-help-circle'></i></div>
                        <div class="menu-title">FAQ</div>
                    </a>
                    <ul>
                        <li><a href="javascript:void(0);" @click="$router.push('/faq-category')"><i class="bx bx-right-arrow-alt"></i>FAQ Category</a></li>
                        <li><a href="javascript:void(0);" @click="$router.push('/faq-content')"><i class="bx bx-right-arrow-alt"></i>FAQ Content</a></li>
                        <li><a href="javascript:void(0);" @click="$router.push('/faq-page-cms')"><i class="bx bx-right-arrow-alt"></i>FAQ Page CMS</a></li>
                    </ul>
                </li>
				<!-- <li>
					<a href="#" class="has-arrow">
						<div class="parent-icon"><i class='lni lni-users'></i></div>
						<div class="menu-title">Our Team</div>
					</a>
					<ul>
						<li><a href="javascript:void(0);" @click="$router.push('/team-member-data')"><i class="bx bx-right-arrow-alt"></i>Team Member Data</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/team-contact-data')"><i class="bx bx-right-arrow-alt"></i>Team Contact Data</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/team-page-cms')"><i class="bx bx-right-arrow-alt"></i>Team Page CMS</a></li>
					</ul>
				</li> -->
				<li>
					<a href="#" class="has-arrow">
						<div class="parent-icon"><i class='bx bx-message-detail'></i></div>
						<div class="menu-title">Contact Page</div>
					</a>
					<ul>
						<li><a href="javascript:void(0);" @click="$router.push('/contact-data')"><i class="bx bx-right-arrow-alt"></i>Contact Data</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/contact-page-cms')"><i class="bx bx-right-arrow-alt"></i>Contact Page CMS</a></li>
					</ul>
				</li>
				<li>
					<a href="#" class="has-arrow">
						<div class="parent-icon"><i class='bx bx-info-circle'></i></div>
						<div class="menu-title">About Us</div>
					</a>
					<ul>
						<li><a href="javascript:void(0);" @click="$router.push('/about-header-cms')"><i class="bx bx-right-arrow-alt"></i>About Header CMS</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/about-content-cms')"><i class="bx bx-right-arrow-alt"></i>About Content CMS</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/about-explore-item-cms')"><i class="bx bx-right-arrow-alt"></i>About Explore Item CMS</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/about-assist-block-cms')"><i class="bx bx-right-arrow-alt"></i>About Assist Block CMS</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/about-team-cms')"><i class="bx bx-right-arrow-alt"></i>About Team CMS</a></li>
					</ul>
				</li>
				<li>
					<a href="#" class="has-arrow">
						<div class="parent-icon"><i class='bx bx-news'></i></div>
						<div class="menu-title">Blog</div>
					</a>
					<ul>
						<li><a href="javascript:void(0);" @click="$router.push('/blog-tag')"><i class="bx bx-right-arrow-alt"></i>Blog Tag</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/blog-category')"><i class="bx bx-right-arrow-alt"></i>Blog Category</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/blog-post')"><i class="bx bx-right-arrow-alt"></i>Blog Post</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/blog-new-post')"><i class="bx bx-right-arrow-alt"></i>New Blog Post</a></li>
						<!-- <li><a href="javascript:void(0);" @click="$router.push('/blog-post-tags')"><i class="bx bx-right-arrow-alt"></i>Blog Post Tags</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/blog-related-post')"><i class="bx bx-right-arrow-alt"></i>Blog Related Post</a></li> -->
						<li><a href="javascript:void(0);" @click="$router.push('/blog-comment')"><i class="bx bx-right-arrow-alt"></i>Blog Comment</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/blog-page-cms')"><i class="bx bx-right-arrow-alt"></i>Blog Page CMS</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/blog-detail-page-cms')"><i class="bx bx-right-arrow-alt"></i>Blog Detail Page CMS</a></li>
					</ul>
				</li>
				<li>
					<a href="#" class="has-arrow">
						<div class="parent-icon"><i class='bx bx-home-circle'></i></div>
						<div class="menu-title">Home</div>
					</a>
					<ul>
						<li><a href="javascript:void(0);" @click="$router.push('/home-slider')"><i class="bx bx-right-arrow-alt"></i>Home Slider</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/home-testimonials')"><i class="bx bx-right-arrow-alt"></i>Home Testimonials</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/home-page-cms')"><i class="bx bx-right-arrow-alt"></i>Home Page CMS</a></li>
						<!-- <li><a href="javascript:void(0);" @click="$router.push('/home-featured-category')"><i class="bx bx-right-arrow-alt"></i>Home Featured Category</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/home-featured-product')"><i class="bx bx-right-arrow-alt"></i>Home Featured Product</a></li> -->
						<!-- <li><a href="javascript:void(0);" @click="$router.push('/home-featured-blog')"><i class="bx bx-right-arrow-alt"></i>Home Featured Blog</a></li> -->
						<!-- <li><a href="javascript:void(0);" @click="$router.push('/slider-product-category')"><i class="bx bx-right-arrow-alt"></i>Slider Product Category</a></li> -->
					</ul>
				</li>
				<li>
					<a href="#" class="has-arrow">
						<div class="parent-icon"><i class='lni lni-cloud'></i></div>
						<div class="menu-title">Site</div>
					</a>
					<ul>
						<li><a href="javascript:void(0);" @click="$router.push('/site-setting')"><i class="bx bx-right-arrow-alt"></i>Site Setting</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/site-social-link')"><i class="bx bx-right-arrow-alt"></i>Site Social Link</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/site-footer-subscriber')"><i class="bx bx-right-arrow-alt"></i>Site Footer Subscriber</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/site-footer-menu-heading')"><i class="bx bx-right-arrow-alt"></i>Site Footer Menu Heading</a></li>
						<!-- <li><a href="javascript:void(0);" @click="$router.push('/site-module')"><i class="bx bx-right-arrow-alt"></i>Site Module</a></li> -->
						<!-- <li><a href="javascript:void(0);" @click="$router.push('/site-pages')"><i class="bx bx-right-arrow-alt"></i>Site Pages</a></li> -->
					</ul>
				</li>
				<!-- <li>
					<a href="#" class="has-arrow">
						<div class="parent-icon"><i class='lni lni-coin'></i></div>
						<div class="menu-title">Order</div>
					</a>
					<ul>
						<li><a href="javascript:void(0);" @click="$router.push('/orders')"><i class="bx bx-right-arrow-alt"></i>Orders</a></li>
					</ul>
				</li> -->
				<!-- <li>
					<a href="#" class="has-arrow">
						<div class="parent-icon"><i class='lni lni-coin'></i></div>
						<div class="menu-title">Payment</div>
					</a>
					<ul>
						<li><a href="javascript:void(0);" @click="$router.push('/payment-method')"><i class="bx bx-right-arrow-alt"></i>Payment Method</a></li>
						<li><a href="javascript:void(0);" @click="$router.push('/payment-history')"><i class="bx bx-right-arrow-alt"></i>Payment History</a></li>
					</ul>
				</li> -->
			</ul>
		</div>
		<!-- End Sidebar -->
		<!-- Start Header -->
		<header>
			<div class="topbar d-flex align-items-center">
				<nav class="navbar navbar-expand">
					<div class="mobile-toggle-menu"><i class='bx bx-menu'></i></div>
					<div class="search-bar flex-grow-1">
						<!-- <div class="position-relative search-bar-box">
							<input type="text" class="form-control search-control" placeholder="Type to search..."> <span class="position-absolute top-50 search-show translate-middle-y"><i class='bx bx-search'></i></span>
							<span class="position-absolute top-50 search-close translate-middle-y"><i class='bx bx-x'></i></span>
						</div> -->
					</div>
					<div class="top-menu ms-auto">
						<ul class="navbar-nav align-items-center">
							<li class="nav-item mobile-search-icon">
								<a class="nav-link" href="#"><i class='bx bx-search'></i></a>
							</li>
							<li class="nav-item dropdown dropdown-large">
								<a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">1</span>
									<i class='bx bx-bell'></i>
								</a>
								<div class="dropdown-menu dropdown-menu-end">
									<a href="javascript:;">
										<div class="msg-header">
											<p class="msg-header-title">Notifications</p>
											<p class="msg-header-clear ms-auto">Marks all as read</p>
										</div>
									</a>
									<div class="header-notifications-list">
										<a class="dropdown-item" href="javascript:;">
											<div class="d-flex align-items-center">
												<div class="notify bg-light-primary text-primary"><i class="bx bx-group"></i></div>
												<div class="flex-grow-1">
													<h6 class="msg-name">New Customers<span class="msg-time float-end">14 sec ago</span></h6>
													<p class="msg-info">5 new user registered</p>
												</div>
											</div>
										</a>
									</div>
									<a href="#">
										<div class="text-center msg-footer">View All Notifications</div>
									</a>
								</div>
							</li>
							<li class="nav-item dropdown dropdown-large">
								<!-- <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">1</span>
									<i class='bx bx-comment'></i>
								</a> -->
								<div class="dropdown-menu dropdown-menu-end">
									<a href="#">
										<div class="msg-header">
											<p class="msg-header-title">Messages</p>
											<p class="msg-header-clear ms-auto">Marks all as read</p>
										</div>
									</a>
									<div class="header-message-list">
										<a class="dropdown-item" href="#">
											<div class="d-flex align-items-center">
												<div class="user-online">
													<img src="../../../../public/core/assets/images/avatars/avatar-1.png" class="msg-avatar" alt="user avatar">
												</div>
												<div class="flex-grow-1">
													<h6 class="msg-name">Daisy Anderson <span class="msg-time float-end">5 sec ago</span></h6>
													<p class="msg-info">The standard chunk of lorem</p>
												</div>
											</div>
										</a>
									</div>
									<a href="#">
										<div class="text-center msg-footer">View All Messages</div>
									</a>
								</div>
							</li>
						</ul>
					</div>
					<div class="user-box dropdown">
						<a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							<img src="../../../../public/core/assets/images/avatars/avatar-1.png" class="user-img" alt="user avatar">
							<div class="user-info ps-3">
								<p class="user-name mb-0">{{ name }}</p>
								<p class="designattion mb-0">{{ email }}</p>
							</div>
						</a>
						<ul class="dropdown-menu dropdown-menu-end">
							<li><a href="javascript:void(0);" @click="$router.push('/')" class="dropdown-item"><i class='bx bx-home-circle'></i><span>Dashboard</span></a></li>
							<li><a @click="logout" class="dropdown-item" href="#"><i class='bx bx-log-out-circle'></i><span>Logout</span></a></li>
						</ul>
					</div>
				</nav>
			</div>
		</header>
		<!-- End Header -->
		<!-- Start Footer -->
		<footer class="page-footer">
			<p class="mb-0">Copyright © 2021. All right reserved.</p>
		</footer>
		<!-- End Footer -->
	</div>
</template>

<script src="../js/home.js"></script>