<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader"/>
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Product</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
              <li class="breadcrumb-item active" aria-current="page">Product Gallery</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <!-- View Modal -->
          <div class="modal fade" id="viewModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Product Image:</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <img :src="$imageURL + 'product/' + singleContent.image" height="300" width="465">
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
          <!-- View Modal -->
          <!-- Edit Modal -->
          <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
            <form @submit.prevent="updateProductGallery(singleContent.id)">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Product Gallery</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Product Name:</label>
                    <input class="form-control" type="text" id="formFile" readonly v-model="singleContent.productName"/>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-12 col-md-4">
                        <label class="form-label">Current Image:</label>
                      </div>
                      <div class="col-12 col-md-8">
                        <img :src="$imageURL + 'product/' + singleContent.image" height="80" width="120"/>
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Upload Product Image:</label>
                    <input class="form-control" type="file" id="formFile" accept="image/gif, image/jpeg, image/png, image/svg" @change="onProductImageChange"/>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Save</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Edit Modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Product Gallery Table</h6>
      <hr/>
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in productGalleryList" :key="index">
                  <td> {{ index + 1 }} </td>
                  <td> {{ row.productName }} </td>
                  <td> <img :src="$imageURL + 'product/' + row.image" height="50"> </td>
                  <td> {{ $filters.formatDate(row.createdAt) }} </td>
                  <td>
                    <div class="d-flex order-actions">
                      <!-- <a href="javascript:void(0);" v-on:click="editProductGallery(row.id)" data-bs-toggle="modal" data-bs-target="#editModal" class="ms-1 edit-button text-white"><i class="bx bxs-edit"></i></a> -->
                      <a href="javascript:void(0);" v-on:click="setId(index)" data-bs-toggle="modal" data-bs-target="#viewModal" class="ms-1 edit-button text-white"><i class="bx bxs-image"></i></a>
                      <!-- <a href="javascript:void(0);" v-on:click="deleteProductGallery(row.id)" class="ms-1 delete-button text-white"><i class="bx bxs-trash"></i></a> -->
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>

<script src="../js/product-gallery.js"></script>