<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Home Page</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Home Page CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body p-4">
                    <h5 class="card-title">Home Page CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="border border-3 p-4 rounded">
                                    <div class="mb-3">
                                        <label class="form-label">Category Sub Title</label>
                                        <input type="text" v-model="categorySubTitle" class="form-control" id="categorySubTitle" placeholder="Enter category sub title">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Category Heading</label>
                                        <input type="text" v-model="categoryHeading" class="form-control" id="categoryHeading" placeholder="Enter category heading">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Category Item Show Length</label>
                                        <input type="text" v-model="categoryItemShowLength" class="form-control" id="categoryItemShowLength" placeholder="Enter category item show length">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Featured Sub Title</label>
                                        <input type="text" v-model="featuredSubTitle" class="form-control" id="featuredSubTitle" placeholder="Enter featured sub title">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Featured Heading</label>
                                        <input type="text" v-model="featuredHeading" class="form-control" id="featuredHeading" placeholder="Enter featured heading">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Featured Item Show Length</label>
                                        <input type="text" v-model="featuredItemShowLength" class="form-control" id="featuredItemShowLength" placeholder="Enter featured item show length">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Featured Button Text</label>
                                        <input type="text" v-model="featuredButtonText" class="form-control" id="featuredButtonText" placeholder="Enter featured button text">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Featured Button URL</label>
                                        <input type="text" v-model="featuredButtonURL" class="form-control" id="featuredButtonURL" placeholder="Enter featured button url">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Testimonial Sub Title</label>
                                        <input type="text" v-model="testimonialSubTitle" class="form-control" id="testimonialSubTitle" placeholder="Enter testimonial sub title">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Testimonial Heading</label>
                                        <input type="text" v-model="testimonialHeading" class="form-control" id="testimonialHeading" placeholder="Enter testimonial heading">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Testimonial Item Show Length</label>
                                        <input type="text" v-model="testimonialItemShowLength" class="form-control" id="testimonialItemShowLength" placeholder="Enter testimonial item show length">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Blog Sub Title</label>
                                        <input type="text" v-model="blogSubTitle" class="form-control" id="blogSubTitle" placeholder="Enter blog title">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Blog Heading</label>
                                        <input type="text" v-model="blogHeading" class="form-control" id="blogHeading" placeholder="Enter blog heading">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Blog Item Show Length</label>
                                        <input type="text" v-model="blogItemShowLength" class="form-control" id="blogItemShowLength" placeholder="Enter blog item show length">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Blog Button Text</label>
                                        <input type="text" v-model="blogButtonText" class="form-control" id="blogButtonText" placeholder="Enter blog button text">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Blog Button URL</label>
                                        <input type="text" v-model="blogButtonURL" class="form-control" id="blogButtonURL" placeholder="Enter blog button url">
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/home-page-cms.js"></script>