import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "HomePageCMS",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            categorySubTitle: null,
            categoryHeading: null,
            categoryItemShowLength: null,
            featuredSubTitle: null,
            featuredHeading: null,
            featuredItemShowLength: null,
            featuredButtonText: null,
            featuredButtonURL: null,
            testimonialSubTitle: null,
            testimonialHeading: null,
            testimonialItemShowLength: null,
            blogSubTitle: null,
            blogHeading: null,
            blogItemShowLength: null,
            blogButtonText: null,
            blogButtonURL: null
        }
    },
    created() {
        document.title = "Gun Buy Sell - Home Page CMS";
        this.getHomePageCMS();
    },
    methods: {
        getHomePageCMS: function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.home.getHomePageCmsURL,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data[0];
                    this.categorySubTitle = this.dataList.category_sub_title;
                    this.categoryHeading = this.dataList.category_heading;
                    this.categoryItemShowLength = this.dataList.category_item_show_length;
                    this.featuredSubTitle = this.dataList.featured_sub_title;
                    this.featuredHeading = this.dataList.featured_heading;
                    this.featuredItemShowLength = this.dataList.featured_item_show_length;
                    this.featuredButtonText = this.dataList.featured_button_text;
                    this.featuredButtonURL = this.dataList.featured_button_url;
                    this.testimonialSubTitle = this.dataList.testimonial_sub_title;
                    this.testimonialHeading = this.dataList.testimonial_heading;
                    this.testimonialItemShowLength = this.dataList.testimonial_item_show_length;
                    this.blogSubTitle = this.dataList.blog_sub_title;
                    this.blogHeading = this.dataList.blog_heading;
                    this.blogItemShowLength = this.dataList.blog_item_show_length;
                    this.blogButtonText = this.dataList.blog_button_text;
                    this.blogButtonURL = this.dataList.blog_button_url;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        update: function() {
            this.isLoading = true;
            let data = {
                category_sub_title: this.categorySubTitle,
                category_heading: this.categoryHeading,
                category_item_show_length: this.categoryItemShowLength,
                featured_sub_title: this.featuredSubTitle ,
                featured_heading: this.featuredHeading,
                featured_item_show_length: this.featuredItemShowLength,
                featured_button_text: this.featuredButtonText,
                featured_button_url: this.featuredButtonURL,
                testimonial_sub_title: this.testimonialSubTitle,
                testimonial_heading: this.testimonialHeading,
                testimonial_item_show_length: this.testimonialItemShowLength,
                blog_sub_title: this.blogSubTitle,
                blog_heading: this.blogHeading,
                blog_item_show_length: this.blogItemShowLength,
                blog_button_text: this.blogButtonText,
                blog_button_url: this.blogButtonURL
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.home.updateHomePageCmsURL,
                data: data,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                this.isLoading = false;
                this.getHomePageCMS();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}