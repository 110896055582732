import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import Mixin from "../../../mixin";
import Select2 from 'vue3-select2-component';

export default {
    name: "Product",
    components: {
        Loading,
        Select2
    },
    mixins: [Mixin],
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            productList: [],
            name: "",
            price: 0,
            description: "",
            videoLink: "",
            location: "",
            state: "",
            owner: "",
            publishStatus: "",
            selectedCategory: "",
            defaultOwner: localStorage.getItem('name'),
            singleContent: [],
            users: [],
            categories: [],
            user: null,
            category: null,
            catId: null,
            userId: null,
            start_date: '',
            end_date: ''
        }
    },
    async created() {
        document.title = "Gun Buy Sell - Product";
        await this.getTeamMembers();
        await this.getProductCategoryList();
        await this.getProductList();
        await this.getCategoryList();
        await this.getUserList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js",
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

    },
    methods: {
        getProductList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.getApprovedProductsURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                this.productList = [];
                if (response.status == 200) {
                    this.productList = response.data;
                    
                    
                    // for (let i = 0; i < this.productList.length; i++) {
                    //     console.log(this.productList[i]['categoryId']);
                    //     for (let j = 0; j < this.productCategoryList.length; j++) {
                    //         if (this.productList[i]['categoryId'] == this.productCategoryList[j]['id']) {
                    //             console.log("matched")
                    //             this.productList[i].push({categoryName: this.productCategoryList[j].name});
                    //             break;
                    //         }
                    //     }
                    // }
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getCategoryList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.getCategoryListURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    // this.categories = response.data;
                    for (const key in Object.keys(response.data)) {
                        this.categories.push({
                            id: response.data[key].id,
                            text: response.data[key].name
                        })
                    }
                    console.log("Catgories are: ", this.categories);

                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getUserList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.getUserListURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    // this.users = response.data;
                    for (const key in Object.keys(response.data)) {
                        this.users.push({
                            id: response.data[key].id,
                            text: response.data[key].name
                        })
                    }

                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        addProduct: async function () {
            this.isLoading = true;
            let data = {
                name: this.name,
                price: this.price,
                description: this.description,
                videoLink: this.videoLink,
                location: this.location,
                state: this.state,
                owner: this.owner.name,
                publishStatus: this.publishStatus,
                categoryId: this.selectedCategory.id,
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.product.addProductURL,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                this.isLoading = false;
                if (response.status == 201) {
                    this.getProductList();
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully stored data!"
                    });
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        myChangeEvent(val) {
            console.log(val);
        },
        mySelectEvent({ id, text }) {
            console.log({ id, text })
        },
        searchProducts: async function () {
            let url = '';
            let params = '';
            this.isLoading = true;
            if (this.category != null) {
                if (params.length > 0) {
                    params += "&categoryId=" + this.category;
                } else {
                    params += "?categoryId=" + this.category;
                }
            }
            if (this.user != null) {
                if (params.length > 0) {
                    params += "&userId=" + this.user;
                } else {
                    params += "?userId=" + this.user;
                }
            }
            if (this.start_date) {
                if (params.length > 0) {
                    params += "&startDate=" + this.start_date;
                } else {
                    params += "?startDate=" + this.start_date;
                }
            }
            if (this.end_date) {
                if (params.length > 0) {
                    params += "&endDate=" + this.end_date;
                } else {
                    params += "?endDate=" + this.end_date;
                }
            }
            if (params) {
                url = this.$serverURL + this.$api.product.getProductsURL + '/search' + params;
            }
            else {
                url = this.$serverURL + this.$api.product.getProductsURL;
            }
            console.log("Params: ", params);
            let config = {
                method: "GET",
                url: url,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                this.productList = [];
                if (response.status == 200) {
                    this.productList = response.data.products;
                    
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        editProduct: async function (id) {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.getSingleProductURL + id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.singleContent = response.data;
                    console.log(this.singleContent.name)
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        updateProduct: async function (id) {
            let data = {
                name: this.singleContent.name,
                price: this.singleContent.price,
                description: this.singleContent.description,
                videoLink: this.singleContent.videoLink,
                location: this.singleContent.location,
                state: this.singleContent.state,
                owner: this.singleContent.owner,
                publishStatus: this.singleContent.name,
                categoryId: this.singleContent.name,
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.product.updateSingleProductURL + id,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.isLoading = false;
                    this.getProductList();
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated!"
                    });
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },

        deleteProduct: function (id) {
            var answer = window.confirm("Are you sure to delete this category?");
            if (answer) {
                this.isLoading = true;
                let config = {
                    method: "DELETE",
                    url: this.$serverURL + this.$api.product.deleteProductURL + id,
                    headers: authHeader()
                };
                this.$axios(config).then((response) => {
                    if (response.data.deleted == true) {
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfully deleted data!"
                        });
                    }
                    this.isLoading = false;
                    this.getProductList();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
        },
    }
}