import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "Dashboard",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dashboardList: [],
            today_all_listing:0,
            total_listing:0,
            today_total_customer:0,
            total_customer:0,
            total_approved_listing:0,
            total_rejected_listing:0,
            total_pending_listing:0,
            today_customer_join_list:[],
            today_all_listing_data:[],
        }
    },
    async created() {
        document.title = "Gun Buy Sell - Dashboard";
        await this.getDashboardList();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getDashboardList: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.dashboard.dashboardListURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                console.log(response);
                if(response.status == 200) {
                    this.dashboardList = response.data;
                    this.today_all_listing = response.data.today_all_listing;
                    this.total_listing = response.data.total_listing;
                    this.today_total_customer = response.data.today_total_customer;
                    this.total_customer = response.data.total_customer;
                    this.total_approved_listing = response.data.total_approved_listing;
                    this.total_rejected_listing = response.data.total_rejected_listing;
                    this.total_pending_listing = response.data.total_pending_listing;
                    this.today_customer_join_list = response.data.today_customer_join_list;
                    this.today_all_listing_data = response.data.today_all_listing_data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
    }
}